import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../helpers/apiRequester";
export interface statsProps {
    dataType: string;
    data: any;
    dataViewTitle: string;
    search: boolean;
    pagination?: boolean;
}
export const ClientsView = (props: statsProps) => {
    const navigate = useNavigate();
    const { data, dataType, dataViewTitle, search, pagination } = props;
    const [showStats, setShowStats] = useState(false);
    const [tableData, setTableData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");
    const [paginationPosition, setPaginationPosition] = useState(0);
    const [showPagination, setShowPagination] = useState(pagination);

    useEffect(() => {
        if (!showStats && data && data.length > 0) {
            setTableData(data);
            setShowStats(true);
        }
    }, [data]);

    const viewClient = (client_id: string) => {
        navigate("/view-client/" + client_id);
    };

    const getTableBody = (td: any) => {
        return (
            <tbody>
                {td.map((elem: any, i: number) => {
                    return (
                        <tr key={i}>
                            <td>{elem.id}</td>
                            <td>{elem.name}</td>
                            <td>{elem.email}</td>
                            <td>{elem.phone}</td>
                            <td>
                                <button onClick={(e: any) => viewClient(elem.id)}>View Client</button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setSearchTerm(newValue);

        if (newValue.length > 2) {
            try {
                const apiResult = await api({
                    url: "search-client",
                    data: { term: searchTerm },
                });
                if (apiResult.data.results.length < 1) {
                    return Report.failure("No Result", "No results match that search term.", "Okay");
                }

                setShowPagination(false);
                setTableData(apiResult.data.results);
            } catch (error) {
                Report.failure("Search Error Occurred", "An error occurred whilst searching for your term. Please contact administrator.", "Okay");
            }
        }
        if (newValue.length == 0) {
            setTableData(data);
            setShowPagination(true);
            setPaginationPosition(0);
        }
    };
    const paginate = async (forward: boolean) => {
        const position = forward ? paginationPosition + 1 : paginationPosition - 1;
        // console.log("START PAGINATE: ", position);
        const paginatedData = await api({ url: "paginate-clients", data: { position: position * 50 } });
        if (paginatedData.data.success) {
            const results = paginatedData.data.results;
            setPaginationPosition(position);
            setTableData(results);
        }
    };

    return (
        <div className={styles.dataView}>
            <h3>{dataViewTitle}</h3>
            <form onSubmit={(e) => handleSubmit(e)}>
                <input className={styles.search} type="search" onChange={onChange} placeholder="Search by name" />
            </form>
            <div className={styles.dataTableContainer}>
                <table>
                    {showStats ? (
                        <>
                            {getTableHeaders(dataType)}
                            {getTableBody(tableData)}
                        </>
                    ) : (
                        <></>
                    )}
                </table>
                <div className={styles.pagination}>
                    {showPagination ? (
                        <div>
                            {paginationPosition > 0 ? (
                                <>
                                    <span onClick={(e: any) => paginate(false)}>Back</span>
                                    <span onClick={(e: any) => paginate(true)}>Next</span>
                                </>
                            ) : (
                                <span onClick={(e: any) => paginate(true)}>Next</span>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

const getTableHeaders = (type: string) => {
    switch (type) {
        case "ALL":
            return (
                <thead>
                    <tr>
                        <th>Client ID</th>
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Client Telephone</th>
                        <th>Action</th>
                    </tr>
                </thead>
            );
            break;
        default:
            break;
    }
};
