import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import api from "../../../../../../helpers/apiRequester";

export const LbaAction = (props: { caseRef: string }) => {
    const { caseRef } = props;

    const lbaRequest = async (action: string) => {
        Loading.circle("Sending ...");
        try {
            const apiResponse = await api({ url: "/actions/lba", data: { case_ref: caseRef, action } });
            const data = apiResponse.data;
            Loading.remove();
            if (data.success && !data.link) {
                window.location.reload();
            } else if (data.success && data.link) {
                window.open(data.link, "_newtab");
            } else {
                Report.failure("Error", "Failed to download LBA", "OKAY");
            }
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            Report.failure("Error", "Action failure", "OKAY");
        }
    };

    const attemptSendLba = async () => {
        Confirm.show(
            "Are you sure?",
            "PE and Sentinel Database will be updated and a new LBA will be sent to the lender using current data.",
            "SEND LBA",
            "GO BACK",
            () => lbaRequest("send"),
            () => {}
        );
    };

    return (
        <div className={styles.singleAction}>
            <h4>LBA</h4>
            <div>
                <p>
                    <span>Preview LBA:</span> <button onClick={(e: any) => lbaRequest("preview")}>Generate Temporary LBA</button>
                </p>
                <p>
                    <span>Send LBA to lender</span>
                    <button onClick={(e: any) => attemptSendLba()}>Send LBA</button>
                </p>
            </div>
        </div>
    );
};
