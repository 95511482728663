import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
import { Report } from "notiflix/build/notiflix-report-aio";
import axios from "axios";
import { Loading } from "notiflix";
import Select from "react-select";
import api from "../../../helpers/apiRequester";
export interface statsProps {
    dataType: string;
    data: any;
    dataViewTitle: string;
    readonly lenders: Array<selectObjectProps>;
}

interface selectObjectProps {
    value: string;
    label: string;
}

export const LenderRequireFoaView = (props: statsProps) => {
    const { data, dataType, dataViewTitle, lenders } = props;
    const [showStats, setShowStats] = useState(false);
    const [newLenderRequirement, setNewLenderRequirement] = useState("");
    const [lenderOptions, setLenderOptions] = useState<Array<selectObjectProps>>(lenders);

    useEffect(() => {
        if (!showStats && data && data.length > 0) {
            setShowStats(true);
        }
    }, [data]);

    const onChange = (option: selectObjectProps | null) => {
        if (option != null) {
            console.log("INPUT SELECT FIELD CHANGED...", option.value);
            setNewLenderRequirement(option.value);
        }
    };
    const addLenderRequirement = async () => {
        if (newLenderRequirement == "") {
            return Report.failure("No Lender Selected", "Please select a lender to be a FOA requirer first.", "Okay");
        }

        try {
            const apiResult = await api({
                url: `/addFoaRequiredLender`,
                method: "POST",
                data: JSON.stringify({ lender: newLenderRequirement }),
            });

            window.location.href = "/FOA-responses";
        } catch (error) {
            console.log(error);
            return Report.failure("Error", "Please request support.", "Okay");
        }
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const removeLender = async (lenderToRemove: string) => {
        try {
            const apiResult = await api({
                url: `/removeFoaRequiredLender`,
                method: "POST",
                data: JSON.stringify({ lender: lenderToRemove }),
            });
            window.location.href = "/FOA-responses";
        } catch (error) {
            console.log(error);
            return Report.failure("Error", "Please request support.", "Okay");
        }
    };

    const getTableBody = () => {
        return (
            <tbody>
                {data.map((elem: any, i: number) => {
                    return elem.lender != "Others" ? (
                        <tr key={i}>
                            <td>{elem.lender}</td>
                            <td>{elem.cases}</td>
                            <td>
                                <button data-msgid={`${i}`} onClick={(e: any) => removeLender(elem.lender)}>
                                    Remove Lender
                                </button>
                            </td>
                        </tr>
                    ) : (
                        <></>
                    );
                })}
            </tbody>
        );
    };

    return (
        <div className={styles.dataView}>
            <h3>{dataViewTitle}</h3>
            <div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Select className={styles.select} options={lenders} onChange={onChange} />
                    <button onClick={(e: any) => addLenderRequirement()}>Add Lender</button>
                </form>
            </div>
            <div className={styles.dataTableContainer}>
                <table>
                    {showStats ? (
                        <>
                            {getTableHeaders(dataType)}
                            {getTableBody()}
                        </>
                    ) : (
                        <></>
                    )}
                </table>
            </div>
        </div>
    );
};

const getTableHeaders = (type: string) => {
    switch (type) {
        case "REQUIRE-FOA":
            return (
                <thead>
                    <tr>
                        <th>Lender</th>
                        <th>Active Cases</th>
                        <th>Remove Lender</th>
                    </tr>
                </thead>
            );
            break;
        default:
            break;
    }
};
