import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../helpers/apiRequester";
export interface statsProps {
    data: any;
}
export const ClientView = (props: statsProps) => {
    const navigate = useNavigate();
    const { data } = props;
    const [showStats, setShowStats] = useState(false);
    const [editing, setEditing] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [clientData, setClientData] = useState(data);

    useEffect(() => {
        // console.log("DATAVIEW : ", data);
    }, [data]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempClientData = { ...clientData };
        tempClientData[e.currentTarget.name] = e.currentTarget.value;
        setClientData(tempClientData);
        setDataChanged(true);
    };

    const saveData = async () => {
        Loading.circle("Saving");
        console.log("SAVE DATA......");
        try {
            const apiResult = await api({
                url: "save-client-info",
                data: { clientDataToSave: clientData },
            });

            Loading.remove();
            if (!apiResult.data.success) return Report.failure("Error", "An error occurred.", "Okay");

            Report.success("Success", "Your update saved successfully.", "Okay");
            setEditing(false);
        } catch (error) {
            Report.failure("Error", "An error occurred.", "Okay");
        }
    };

    const handleEditToggle = () => {
        if (editing) setClientData(data);
        setEditing(!editing);
    };

    return (
        <div className={styles.clientView}>
            <h3>
                <span>Client Information</span>
                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? "Cancel" : "Edit"}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h3>
            <form onSubmit={(e) => handleSubmit(e)}>
                <fieldset>
                    <label>First name</label>
                    <input
                        className={styles.textInput}
                        name={"first_name"}
                        value={clientData.first_name}
                        type="text"
                        onChange={onChange}
                        disabled={!editing}
                    />
                </fieldset>
                <fieldset>
                    <label>Last name</label>
                    <input
                        className={styles.textInput}
                        name={"last_name"}
                        value={clientData.last_name}
                        type="text"
                        onChange={onChange}
                        disabled={!editing}
                    />
                </fieldset>
                <fieldset>
                    <label>Title</label>
                    <input className={styles.textInput} name={"title"} value={clientData.title} type="text" onChange={onChange} disabled={!editing} />
                </fieldset>
                <fieldset>
                    <label>Date of Birth</label>
                    <input className={styles.textInput} name={"dob"} value={clientData.dob} type="text" onChange={onChange} disabled={!editing} />
                </fieldset>
                <fieldset>
                    <label>Email</label>
                    <input className={styles.textInput} name={"email"} value={clientData.email} type="text" onChange={onChange} disabled={!editing} />
                </fieldset>
                <fieldset>
                    <label>Telephone</label>
                    <input className={styles.textInput} name={"phone"} value={clientData.phone} type="text" onChange={onChange} disabled={!editing} />
                </fieldset>
                <div className={styles.formDiv}>
                    <h4>Client Address</h4>
                    <fieldset>
                        <label>Building name</label>
                        <input
                            className={styles.textInput}
                            name={"building"}
                            value={clientData.building}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>House No. street name</label>
                        <input
                            className={styles.textInput}
                            value={clientData.building_no_street}
                            name={"building_no_street"}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Town</label>
                        <input
                            className={styles.textInput}
                            name={"town_area"}
                            value={clientData.town_area}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>City</label>
                        <input
                            className={styles.textInput}
                            name={"city"}
                            value={clientData.city}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Postcode</label>
                        <input
                            className={styles.textInput}
                            name={"postcode"}
                            value={clientData.postcode}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                </div>

                <fieldset>
                    <label>Practice Evolve Key</label>
                    <input
                        className={styles.textInput}
                        name={"solicitor_id"}
                        value={clientData.solicitor_id}
                        type="text"
                        onChange={onChange}
                        disabled={true}
                    />
                </fieldset>
            </form>
        </div>
    );
};
