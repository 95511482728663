import s from "./styles.module.scss";

export interface wrapperProps {
    children: React.ReactNode;
}

export const Wrapper = (props: wrapperProps) => {
    const { children } = props;
    return <div className={s.wrapper}>{children}</div>;
};
