import { useEffect } from "react";

function Login() {
    useEffect(() => {
        window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
    }, []);

    return <div className="Login"></div>;
}

export default Login;
