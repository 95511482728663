import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from '../components/layout/header/header'
import Footer from '../components/layout/footer/footer'
import { useParams } from 'react-router-dom'
import { useUpdateAuthUserData } from '../authUserProvider'

declare global {
    interface Window {
        dataLayer: any
    }
}
function Main() {
    const updateAuthUser = useUpdateAuthUserData()

    useEffect(() => {
        const userString = window.sessionStorage.getItem('user')
        if (userString) updateAuthUser(JSON.parse(userString))
    }, [])

    return (
        <div className="main">
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Main
