import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { LenderRequireFoaView } from "../components/views/lenderRequireFoaView";
import { StatsView } from "../components/views";
import { DataView } from "../components/views/dataBreakdownView";

function LenderFoaResponses() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [foaData, setFoaData] = useState<any>();

    useEffect(() => {
        if (!dataReady && !loading) {
            // console.log("LOADING.... GET DATA");
            Loading.circle("Loading...");
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    const getData = async () => {
        try {
            const apiResponse = await api("/foa-response-data");
            const data = apiResponse.data;
            console.log("FOA DATA: ", data);

            setFoaData(data);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("");
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="foa-response">
            <Sidebar />
            <MainView>
                <ViewTitle text={"FOA Lender Response Overview"} />
                <div>{dataReady ? <StatsView stats={foaData.stats} /> : <></>}</div>
                <div>
                    {dataReady ? (
                        <DataView dataViewTitle="FOA Lender Response Breakdown" dataType="FOA-RESPONSE" data={foaData.lenderFoaFigures} />
                    ) : (
                        <></>
                    )}
                </div>
                <div>
                    {dataReady ? (
                        <LenderRequireFoaView
                            dataViewTitle="Lenders Requiring FOA"
                            dataType="REQUIRE-FOA"
                            data={foaData.lendersRequiringFoa}
                            lenders={foaData.lenders}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </MainView>
        </div>
    );
}

export default LenderFoaResponses;
