import s from './styles.module.scss'
import 'react-tabs/style/react-tabs.css'
import { formatColumnName } from '../../../helpers/functions'

export interface dataTableProps {
    data: any
    viewButtons?: true | false
    viewButtonText?: string
    viewBaseUrl?: string
    viewUrlColumn?: string
    exportButton?: true | false
    search?: true | false
    searchText?: string
    searchOnChange?: any
}

export const DataTable = (props: dataTableProps) => {
    const {
        data,
        viewButtons,
        viewButtonText,
        viewBaseUrl,
        viewUrlColumn,
        exportButton,
        search,
        searchText,
        searchOnChange,
    } = props

    console.log('dataTable data', data)
    if (typeof data != 'object' || !data.length) {
        console.log('data', data)
        throw new Error('type of data is not an array.')
    }

    const headers: any = []
    const rows: any = []

    const columns = Object.keys(data[0])

    if (viewButtons && viewBaseUrl) {
        headers.push(<th key={Math.random()}></th>)
    }

    for (let j = 0; j < data.length; j++) {
        let rowSeed: any = []

        if (viewButtons && viewBaseUrl) {
            let linkUrl = viewBaseUrl

            if (viewUrlColumn) {
                linkUrl = `${viewBaseUrl}${data[j][viewUrlColumn]}`
            }

            rowSeed = [
                <td key={Math.random()}>
                    <a href={linkUrl}>{viewButtonText ?? 'View'}</a>
                </td>,
            ]
        }

        rows.push(rowSeed)
    }

    for (let i = 0; i < columns.length; i++) {
        const column = columns[i]

        headers.push(<th key={Math.random()}>{formatColumnName(column)}</th>)

        for (let j = 0; j < data.length; j++) {
            const element = data[j]
            rows[j].push(<td key={Math.random()}>{element[column]}</td>)
        }
    }

    return (
        <div className={s.dataTable}>
            {search && (
                <div className={s.toolbar}>
                    <input
                        className={s.searchInput}
                        type="search"
                        onChange={searchOnChange}
                        placeholder={searchText ?? 'Search...'}
                    />
                    {exportButton && <button>Export</button>}
                </div>
            )}
            <div className={s.tableContainer}>
                <table>
                    <thead>
                        <tr className={s.tableHeader}>{headers}</tr>
                    </thead>
                    <tbody>
                        {rows.map((e: any) => {
                            return <tr key={Math.random()}>{e}</tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
