import s from "./styles.module.scss";

export interface viewTitleProps {
    text: String;
}

export const ViewTitle = (props: viewTitleProps) => {
    const { text } = props;
    return (
        <div className={s.titleContainer}>
            <h1>{text}</h1>
        </div>
    );
};
