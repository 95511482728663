import axios from 'axios'

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 5000000,
    method: 'POST',
    // headers: { "Content-Type": "application/json" }
    headers: { 'Content-Type': 'application/json' },
})

const apiGet = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 5000000,
    method: 'POST',
    // headers: { "Content-Type": "application/json" }
    headers: { 'Content-Type': 'application/json' },
})

api.interceptors.request.use(
    async (config: any) => {
        const storedAccessToken = window.sessionStorage.getItem('access')
        if (!storedAccessToken) {
            console.log('no access token for session')
            window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            return
        }
        if (storedAccessToken && storedAccessToken !== config.headers.access_token) {
            config.headers.Authorization = storedAccessToken
        }

        return config
    },

    (error) => {
        console.log('interceptor error: ', error)

        return Promise.reject(error)
    }
)

export default api
