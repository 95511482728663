import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import api from "../../../helpers/apiRequester";
import Select from "react-select";
import { feeEarners } from "../../../data";
import { selectObjectProps } from "../../../interfaces";
import { BulkCaseSelector } from "../../reusable/bulkCaseSelector";

export const FeeEarnerBulkUpdateModalContent = () => {
    const [bulkPayload, setBulkPayload] = useState<any>({ feeEarner: "", cases: [] });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        if (option != null) {
            let feeEarner = { feeEarner: option.value, cases: bulkPayload.cases };
            setBulkPayload(feeEarner);
        }
    };

    const updateCases = (groupToSave: Array<any>) => {
        setBulkPayload({ feeEarner: bulkPayload.feeEarner, cases: groupToSave });
    };

    const assignCases = async () => {
        if (bulkPayload.cases.length < 1 || bulkPayload.feeEarner.length < 1) {
            return Report.failure("Error", "Please select a fee earner and a case to assign.", "Okay");
        }
        Loading.circle("Assigning Cases, please wait.");
        try {
            const apiResult = await api({
                url: "actions/bulk",
                data: { action: "assign-fee-earner", bulkPayload },
            });
            Loading.remove();
            if (apiResult.data.success) return Report.success("Assigned", "Your cases were assigned to the selected fee earner.", "Okay");
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                Report.failure("An error occurred", "An error occurred whilst assigning cases to the fee earner.", "Okay");
            }
        }
    };

    return (
        <div className={styles.FeeEarnerBulkUpdate}>
            <h2>Bulk Assign Fee Earners</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
                <fieldset>
                    <label>Select a fee earner to assign cases:</label>
                    <Select
                        className={styles.selectFeeEarners}
                        options={feeEarners}
                        onChange={(selected) => onSelectChange(selected, "fee_earner")}
                        name={"fee_earner"}
                        value={feeEarners.find((fe) => fe.value == bulkPayload.feeEarner)}
                    />
                </fieldset>
            </form>
            <div className={styles.casesToAssign}>
                <BulkCaseSelector saveGroup={updateCases} />
            </div>
            <button className={styles.assignButton} onClick={(e) => assignCases()}>
                Assign Cases
            </button>
        </div>
    );
};
