import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import api from "../../../../../../helpers/apiRequester";

export const Part36Action = (props: { caseRef: string }) => {
    const { caseRef } = props;

    const part36Request = async (action: string) => {
        Loading.circle("Sending ...");
        try {
            const apiResponse = await api({ url: "/actions/part36", data: { case_ref: caseRef, action } });
            const data = apiResponse.data;
            Loading.remove();
            if (data.success && !data.link) {
                window.location.reload();
            } else if (data.success && data.link) {
                window.open(data.link, "_newtab");
            } else {
                Report.failure("Error", "Failed to download LBA", "OKAY");
            }
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            Report.failure("Error", "Action failure", "OKAY");
        }
    };

    const attemptSendPart36 = async () => {
        Confirm.show(
            "Are you sure?",
            "Send a Part 36 signature request to the client.",
            "SEND Part36",
            "GO BACK",
            () => part36Request("send"),
            () => {}
        );
    };

    return (
        <div className={styles.singleAction}>
            <h4>Part 36</h4>
            <div>
                <p>
                    <span>Preview part36 cover letter:</span>
                    <button onClick={(e: any) => part36Request("preview-coverletter")}>Generate Temporary cover letter</button>
                </p>
                <p>
                    <span>Preview part36 form:</span> <button onClick={(e: any) => part36Request("preview-form")}>Generate Temporary form</button>
                </p>
                <p>
                    <span>Send part36 signature request to client </span>
                    <button onClick={(e: any) => attemptSendPart36()}>Send Part36</button>
                </p>
            </div>
        </div>
    );
};
