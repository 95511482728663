import styles from './styles.module.scss'
import pageStyles from '../../../globals/pages.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import { Wrapper } from '../../reusable'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import profile from '../../../assets/profile.png'
import logout from '../../../assets/logout.png'
import menu from '../../../assets/menu.png'
import { IsMobile } from '../isMobile/isMobile'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useAuthUserData } from '../../../authUserProvider'

function Header() {
    const authUser = useAuthUserData()
    const [username, setUsername] = useState('')
    const [usernameChar, setUsernameChar] = useState('')

    const toggleSidebar = () => {
        console.log('TOGGLING..')
    }
    useEffect(() => {
        if (typeof authUser != 'undefined') {
            let name = authUser.email.split('@')[0].substring(1)
            const firstletter = authUser.email.charAt(0).toLocaleUpperCase()
            setUsername(firstletter + name)
            setUsernameChar(firstletter)
        }
    }, [authUser])

    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                <div className={`${styles.portalUserInfo} ${responsiveStyles.portalInfo} `}>
                    {IsMobile() ? (
                        <>
                            <div className={`${responsiveStyles.initialsCircle}`}>
                                <p>{usernameChar}</p>
                            </div>{' '}
                            <button
                                className={`${responsiveStyles.menu}`}
                                onClick={() => {
                                    toggleSidebar()
                                }}
                            >
                                <img src={menu} />
                            </button>
                        </>
                    ) : (
                        <>
                            <div className={`${styles.profileImgContainer}`}>
                                <img src={profile} alt="portal profile" />
                            </div>
                            <p>{username}</p>
                        </>
                    )}
                </div>
                <div className={`${styles.logout} ${responsiveStyles.logout} `}>
                    <a
                        href={`${process.env.REACT_APP_COGNITO_APP_URL}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=https://dashboard.sentinellegal.co.uk/login`}
                    >
                        <img src={logout} alt="log out" />
                        Logout
                    </a>
                </div>
            </div>
        </header>
    )
}

export default Header
