import React, { useContext, useState } from 'react'

export interface contextProps {
    children: React.ReactNode
}

const AuthUserContext = React.createContext<any>('')
const AuthUserUpdateContext = React.createContext<any>('')

export function useAuthUserData() {
    return useContext(AuthUserContext)
}

export function useUpdateAuthUserData() {
    return useContext(AuthUserUpdateContext)
}

export function AuthUserProvider({ children }: contextProps) {
    const [authUserData, setAuthUserData] = useState<any>()

    function updateAuthUser(au: any) {
        setAuthUserData(au)
    }
    return (
        <AuthUserContext.Provider value={authUserData}>
            <AuthUserUpdateContext.Provider value={updateAuthUser}>{children}</AuthUserUpdateContext.Provider>
        </AuthUserContext.Provider>
    )
}
