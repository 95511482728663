import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import Select from 'react-select'
import Modal from '../components/reusable/modal'

import globalStyles from '../globals/pages.module.scss'
import { TabsBlock } from '../components/reusable/tabBlock'
import { DataTable } from '../components/reusable/dataTable'
import { RecentStatsModalContent } from '../components/modalContent/recentStatsContent'
import { ComparisonView } from '../components/views/comparisonView'

interface selectObjectProps {
    value: string
    label: string
}

let defaultStats: any = [
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' }],
    },
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' }],
    },
]

export default function LocStats() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [recentStats, setShowRecentStats] = useState(false)
    const [recentStatsData, setRecentStatsData] = useState<any>({})
    const [reports, setReports] = useState<any>({})

    const [loading, setLoading] = useState(false)
    const [pageData, setPageData] = useState<any>({
        stats: defaultStats,
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [tableData, setTableData] = useState<any>([{ title: 'Loading...', data: [{ loading: 'loading...' }] }])

    useEffect(() => {
        getData()
    }, [])

    const toggleStatsModal = () => {
        setShowRecentStats(!recentStats)
    }
    const getData = async () => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({
                url: '/loc-stats',
                data: {},
            })
            const data = apiResponse.data
            console.log('data', data)
            setPageData(data)
            setRecentStatsData(data.recentStatsData)
            setReports(data.report)
            setDataReady(true)
            Loading.remove()
        } catch (error: any) {
            Loading.remove()
            if (error?.response?.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                console.log('Whoops!', error) //DEBUG
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={'Latest LOC Overview'} />
                <div>
                    <StatTile title="Total LOC Sent" stat={recentStatsData.sent} />
                    <StatTile title="Total LOC Acknowledged" stat={recentStatsData.acknowledged} />
                </div>
                <br />
                <h3 style={{ margin: '0 0 20px' }}>Historical LOC Updates</h3>
                {dataReady && <DataTable data={reports} />}
            </MainView>
            <Modal modalState={recentStats} previewModalAction={toggleStatsModal}>
                <RecentStatsModalContent statusData={recentStatsData} />
            </Modal>
        </div>
    )
}
