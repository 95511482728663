import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'
import api from '../../../helpers/apiRequester'
import Select from 'react-select'
import { feeEarners } from '../../../data'
import { selectObjectProps } from '../../../interfaces'
import { BulkCaseSelector } from '../../reusable/bulkCaseSelector'

export const RecentStatsModalContent = (statusData: any) => {
    const [recentStatsData, setRecentStatsData] = useState<any>({})

    useEffect(() => {
        console.log(statusData)

        if (statusData) {
            setRecentStatsData(statusData)
        }
    }, [statusData])

    return (
        <div className={styles.recentStatsContainer}>
            <h2>Current DSAR Statuses</h2>

            <div className={styles.recentStatsContainerInner}>
                <ul>
                    {recentStatsData?.statusData &&
                        Object.keys(recentStatsData.statusData)?.map((stat: any) => {
                            return (
                                <li>
                                    {stat}: <span>{recentStatsData.statusData[stat]}</span>
                                </li>
                            )
                        })}
                </ul>
            </div>
        </div>
    )
}
