import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import Select from 'react-select'

import globalStyles from '../globals/pages.module.scss'
import { Tab, Tabs } from 'react-tabs'
import { TabsBlock } from '../components/reusable/tabBlock'

interface selectObjectProps {
    value: string
    label: string
}
const dataSetOptions: Array<selectObjectProps> = [
    {
        value: 'overview',
        label: 'Overview',
    },
]

let stats: any = {
    overview: [
        {
            title: 'Clients',
            values: [
                { 'Confirmed': 0 },
                { 'Pending': 0 },
                { 'Cancelled': 0 },
                { 'Total': 0 },
            ],
        },
        {
            title: 'Cases',
            values: [
                { 'Confirmed': 0 },
                { 'Pending': 0 },
                { 'Cancelled': 0 },
                { 'Total': 0 },
            ],
        },
    ],
}
export default function FosCases() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeData, setActiveData] = useState<selectObjectProps>(dataSetOptions[0])
    const [overviewData, setOverviewData] = useState<any>({
        stats: stats[activeData.value],
    })

    console.log("overviewData", overviewData) //DEBUG

    useEffect(() => {
        if (!dataReady && !loading) {
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    useEffect(() => {
        getData();
    }, [activeData, loading]);

    const getData = async () => {
        Loading.circle("Loading...");
        try {
            const apiResponse = await api({
                url: "/fos-imports-data",
                data: { dataGroup: activeData.value },
            });
            console.log("response", apiResponse) //DEBUG
            const data = apiResponse.data;

            setOverviewData(data);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response?.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log('input label: ', label)
        if (option != null) setActiveData(option)
    }

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={'FOS Cases'} />
                <div className={globalStyles.funderSelectContainer}>
                    <label>Select Type</label>
                    <Select
                        className={globalStyles.select}
                        options={dataSetOptions}
                        onChange={(value) => onSelectChange(value, 'changeDataSet')}
                        name={'changeDataSet'}
                        value={activeData}
                    />
                </div>{' '}
                <div>{dataReady && overviewData?.stats.length ? <StatsView stats={overviewData.stats} /> : <></>}</div>
            </MainView>
        </div>
    )
}
