import s from "./styles.module.scss";
import { Link } from "react-router-dom";

interface SubmitButtonProps {
    text: string;
    btnType: string;
    onClick: (event: React.MouseEvent) => void;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { text, onClick, btnType } = props;
    return (
        <button onClick={onClick} className={`${s.cta} ${btnType === "primary" ? s.primary : btnType === "secondary" ? s.secondary : s.third}`}>
            <span>{text}</span>
            {/* <img src={btnType == "secondary" ? goldArrow : blackArrow} /> */}
        </button>
    );
};
