import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'

import api from '../../../../../../helpers/apiRequester'

export const LoaAction = (props: { caseRef: string }) => {
    const { caseRef } = props

    const loaRequest = async (action: string) => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({ url: '/actions/loa', data: { case_ref: caseRef, action } })
            const data = apiResponse.data
            Loading.remove()
            if (action === 'preview' || action === 'preview-doc') {
                if (data.success && data.link) {
                    window.open(data.link, '_newtab')
                } else {
                    Report.failure('Error', 'Failed to complete action: ' + action, 'OKAY')
                }
            } else if (action == 'send') {
                if (data.success && data.loaSent) {
                    Report.success('Success', 'LOA link sent to client.', 'OKAY', () => window.location.reload())
                } else {
                    Report.failure('Error', 'Failed to send LOA link to client', 'OKAY')
                }
            }
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            Report.failure('Error', 'Action failure', 'OKAY')
        }
    }
    const attemptSendLoa = async () => {
        Confirm.show(
            'Are you sure?',
            'You about to send the client a portal link to sign a LOA document.',
            'SEND LOA',
            'GO BACK',
            () => loaRequest('send'),
            () => {}
        )
    }

    return (
        <div className={styles.singleAction}>
            <h4>FOA</h4>
            <div>
                <p>
                    <span>Preview FOA document:</span>{' '}
                    <button onClick={(e: any) => loaRequest('preview-doc')}>Generate FOA Document</button>
                </p>
                <p>
                    <span>Test FOA Link:</span>{' '}
                    <button onClick={(e: any) => loaRequest('preview')}>View FOA Portal</button>
                </p>{' '}
                <p>
                    <span>Send FOA Portal Link:</span> <button onClick={(e: any) => attemptSendLoa()}>Send Link</button>
                </p>
            </div>
        </div>
    )
}
