import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../../helpers/apiRequester";
import Select from "react-select";
import { selectObjectProps } from "../../../../interfaces";
import { feeEarners } from "../../../../data";
import { PocModalView } from "../../pocModalView";
export interface statsProps {
    data: any;
}

export const CaseDetailsView = (props: statsProps) => {
    const { data } = props;
    const [editing, setEditing] = useState(false);
    const [hasPocData, setHasPocData] = useState(true);
    const [caseData, setCaseData] = useState(data);
    const [feeEarner, setFeeEarner] = useState(feeEarners.find((fe) => fe.value == data.fee_earner));
    const [dataChanged, setDataChanged] = useState(false);
    const [addPocModal, setAddPocModal] = useState(false);

    useEffect(() => {
        // console.log("CASE INFO DATAVIEW : ", data);
        setCaseData(data);
        console.log(data);
        setHasPocData(data.hasPoc);
    }, [data]);

    // useEffect(() => {
    //     // console.log("CASE INFO DATAVIEW : ", data);
    //     // setCaseData(data);
    //     console.log("case data: ", caseData);
    // }, [caseData]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempCaseData = { ...caseData };
        tempCaseData[e.currentTarget.name] = e.currentTarget.value;
        setCaseData(tempCaseData);
        setDataChanged(true);
    };

    const saveData = async () => {
        Loading.circle("Saving");
        setEditing(false);
        console.log("SAVE DATA......");
        try {
            const apiResult = await api({
                url: "save-case-info",
                data: { caseDataToSave: caseData },
            });
            Loading.remove();
            if (!apiResult.data.success) return Report.failure("Error", "An error occurred.", "Okay");

            Report.success("Success", "Your update saved successfully.", "Okay");
        } catch (error) {
            Report.failure("Error", "Couldnt save data.", "Okay");
        }
    };
    const togglePocModal = (refresh: boolean | null = null) => {
        console.log("TOGGLING....refresh:", refresh);
        if (refresh) window.location.reload();
        setAddPocModal(!addPocModal);
    };

    const handleEditToggle = () => {
        if (editing) {
            setCaseData(data);
        }
        setEditing(!editing);
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log("input label: ", label);
        if (option != null) {
            console.log("INPUT SELECT FIELD CHANGED...", option.value);
            const updateItem = option.label;
            const updateValue = option.value;

            let tempCaseData = { ...caseData };
            tempCaseData.fee_earner = updateValue;
            console.log("data to save : ", tempCaseData);

            setFeeEarner(feeEarners.find((fe) => fe.value == updateValue));
            setCaseData(tempCaseData);
            setDataChanged(true);
        }
    };

    return (
        <div className={styles.detailsView}>
            <h4>
                <span>Details</span>

                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? "Cancel" : "Edit"}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h4>
            <div className={styles.viewContainer}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>
                        <label>Policy Number</label>
                        <input
                            className={styles.textInput}
                            value={caseData.policy_number}
                            name={"policy_number"}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Lender</label>
                        <input
                            className={styles.textInput}
                            name={"lender"}
                            value={caseData.lender}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Defendant Email</label>
                        <input
                            className={styles.textInput}
                            name={"defendant_email"}
                            value={caseData.defendant_email}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Defendant Address</label>
                        <input
                            className={styles.textInput}
                            name={"defendant_address"}
                            value={caseData.defendant_address}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle Make</label>
                        <input
                            className={styles.textInput}
                            name={"vehicle_make"}
                            value={caseData.vehicle_make}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle Model</label>
                        <input
                            className={styles.textInput}
                            name={"vehicle_model"}
                            value={caseData.vehicle_model}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle Registration</label>
                        <input
                            className={styles.textInput}
                            name={"vehicle_registration"}
                            value={caseData.vehicle_registration}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Interest Rate</label>
                        <input className={styles.textInput} name={"apr"} value={caseData.apr} type="text" onChange={onChange} disabled={!editing} />
                    </fieldset>
                    <fieldset>
                        <label>Credit Amount</label>
                        <input
                            className={styles.textInput}
                            name={"credit_amount"}
                            value={caseData.credit_amount}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Quantum Value</label>
                        <input
                            className={styles.textInput}
                            name={"quantum_value"}
                            value={caseData.quantum_value}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Fee Earner</label>
                        <Select
                            className={styles.select}
                            options={feeEarners}
                            onChange={(value) => onSelectChange(value, "fee_earner")}
                            name={"fee_earner"}
                            isDisabled={!editing}
                            value={feeEarner}
                        />
                    </fieldset>
                    <fieldset></fieldset>

                    <fieldset>
                        <label>Settlement Figure</label>
                        <input
                            className={styles.textInput}
                            name={"settlement_figure"}
                            value={caseData.settlement_figure}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Commission Amount</label>
                        <input
                            className={styles.textInput}
                            name={"disclosed_commission"}
                            value={caseData.disclosed_commission}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Allocated Court Name</label>
                        <input
                            className={styles.textInput}
                            name={"allocated_court_name"}
                            value={caseData.allocated_court_name}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Allocated Court Address</label>
                        <input
                            className={styles.textInput}
                            name={"allocated_court_address"}
                            value={caseData.allocated_court_address}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Court issued Claim Number</label>
                        <input
                            className={styles.textInput}
                            name={"court_claim_number"}
                            value={caseData.court_claim_number}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Defendant Solicitor</label>
                        <input
                            className={styles.textInput}
                            name={"defendant_solicitor"}
                            value={caseData.defendant_solicitor}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Stat Interest Amount</label>
                        <input
                            className={styles.textInput}
                            name={"stat_interest_amount"}
                            value={caseData.stat_interest_amount}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Total Claim</label>
                        <input
                            className={styles.textInput}
                            name={"total_claim"}
                            value={caseData.total_claim}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    {hasPocData ? (
                        <>
                            <div className={styles.pocDataDiv}>
                                <h4>Particulars of Claim</h4>
                                <fieldset>
                                    <label>Finance Agreement Date</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.finance_agreement_date}
                                        name={"finance_agreement_date"}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Broker</label>
                                    <input
                                        className={styles.textInput}
                                        name={"broker"}
                                        value={caseData.broker}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Cash Price</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.cash_price}
                                        name={"cash_price"}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Deposit</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.deposit}
                                        name={"deposit"}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Total Amount Payable</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.total_amount_payable}
                                        name={"total_amount_payable"}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Total Cost of Credit</label>
                                    <input
                                        className={styles.textInput}
                                        name={"total_credit_cost"}
                                        value={caseData.total_credit_cost}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>First Repayment</label>
                                    <input
                                        className={styles.textInput}
                                        name={"first_repayment"}
                                        value={caseData.first_repayment}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Monthly Repayment</label>
                                    <input
                                        className={styles.textInput}
                                        name={"monthly_repayment"}
                                        value={caseData.monthly_repayment}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Final Repayment</label>
                                    <input
                                        className={styles.textInput}
                                        name={"final_repayment"}
                                        value={caseData.final_repayment}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset> </fieldset>
                            </div>
                            <div className={styles.pocDataDiv}>
                                <h4>Court Information</h4>
                                <fieldset>
                                    <label>Court Name</label>
                                    <input
                                        className={styles.textInput}
                                        name={"court_name"}
                                        value={caseData.court_name}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Court Address</label>
                                    <input
                                        className={styles.textInput}
                                        name={"court_address"}
                                        value={caseData.court_address}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <h4>Document Information</h4>
                                <div className={styles.pocDataDiv}>
                                    <fieldset>
                                        <label>Entity Name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"defendant_name"}
                                            value={caseData.defendant_name}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant for Service Name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_defendant_name"}
                                            value={caseData.service_defendant_name}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                </div>
                                <h4>Defendant Information</h4>
                                <div className={styles.formDiv}>
                                    <fieldset>
                                        <label>Defendant Building Name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"building"}
                                            value={caseData.building}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant House No. Street name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"house_no_street_name"}
                                            value={caseData.house_no_street_name}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant Town</label>
                                        <input
                                            className={styles.textInput}
                                            name={"town"}
                                            value={caseData.town}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant City</label>
                                        <input
                                            className={styles.textInput}
                                            name={"city"}
                                            value={caseData.city}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant Postcode</label>
                                        <input
                                            className={styles.textInput}
                                            name={"postcode"}
                                            value={caseData.postcode}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                </div>
                                <div className={styles.formDiv}>
                                    <fieldset>
                                        <label>Defendant for Service Building Name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_building"}
                                            value={caseData.service_building}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant for Service House No. Street name</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_house_no_street_name"}
                                            value={caseData.service_house_no_street_name}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant for Service Town</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_town"}
                                            value={caseData.service_town}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant for Service City</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_city"}
                                            value={caseData.service_city}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label>Defendant for Service Postcode</label>
                                        <input
                                            className={styles.textInput}
                                            name={"service_postcode"}
                                            value={caseData.service_postcode}
                                            type="text"
                                            onChange={onChange}
                                            disabled={!editing}
                                        />
                                    </fieldset>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.pocDataDiv}>
                                <h4>
                                    Particulars of Claim & N1 Information (Not Collected)
                                    <button
                                        onClick={(e: any) => {
                                            togglePocModal();
                                        }}
                                    >
                                        Add
                                    </button>
                                </h4>
                                <br />
                                <br />
                            </div>
                            {!addPocModal ? <></> : <PocModalView case_ref={caseData.case_ref} toggleModal={togglePocModal} />}
                        </>
                    )}
                    <fieldset>
                        <label>Practice Evolve Key</label>
                        <input className={styles.textInput} value={caseData.solicitor_id} type="text" onChange={onChange} disabled={true} />
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
