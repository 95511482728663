import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { StatsView } from "../components/views";
import Select from "react-select";

import globalStyles from "../globals/pages.module.scss";

interface selectObjectProps {
    value: string;
    label: string;
}
const dataSetOptions: Array<selectObjectProps> = [
    {
        value: "all",
        label: "All Data",
    },
    {
        value: "Horizon",
        label: "Horizon Data",
    },
    {
        value: "Woodville",
        label: "Woodville Data",
    },
    {
        value: "CX",
        label: "CX Data",
    },
];

function Home() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [overviewData, setOverviewData] = useState<any>();
    const [activeData, setActiveData] = useState<selectObjectProps>({
        value: "all",
        label: "All Data",
    });

    useEffect(() => {
        if (!dataReady && !loading) {
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    useEffect(() => {
        getData();
    }, [activeData, loading]);

    const getData = async () => {
        Loading.circle("Loading...");
        try {
            const apiResponse = await api({
                url: "/system-overview",
                data: { dataGroup: activeData.value },
            });
            const data = apiResponse.data;
            // console.log("SYSTEM OVERVIEW DATA: ", data);

            setOverviewData(data);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log("input label: ", label);
        if (option != null) setActiveData(option);
    };

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={"System Overview"} />
                <div className={globalStyles.funderSelectContainer}>
                    <label>Select Funder Data</label>
                    <Select
                        className={globalStyles.select}
                        options={dataSetOptions}
                        onChange={(value) => onSelectChange(value, "changeDataSet")}
                        name={"changeDataSet"}
                        value={activeData}
                    />
                </div>
                <div>{dataReady ? <StatsView stats={overviewData.stats} /> : <></>}</div>
            </MainView>
        </div>
    );
}

export default Home;
