import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import Select from 'react-select'

import globalStyles from '../globals/pages.module.scss'
import { TabsBlock } from '../components/reusable/tabBlock'
import { DataTable } from '../components/reusable/dataTable'

interface selectObjectProps {
    value: string
    label: string
}

let defaultStats: any = [
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' },],
    },
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' },],
    },
]

export default function FosTransfers() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pageData, setPageData] = useState<any>({
        stats: defaultStats,
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [tableData, setTableData] = useState<any>([
        {title: 'Loading...', data: [
            {loading: 'loading...'}
        ]}
    ])

    // useEffect(() => {
    //     if (!dataReady && !loading) {
    //         setLoading(true)
    //         getData()
    //     }
    // }, [dataReady, loading])

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({
                url: '/fos-transfers-data',
                data: { searchTerm: searchTerm },
            })
            console.log("here!") //DEBUG
            const data = apiResponse.data
            console.log('data', data) //DEBUG

            setTableData(data.tableData)
            setPageData(data)
            setDataReady(true)
            Loading.remove()
        } catch (error: any) {
            Loading.remove()
            if (error?.response?.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                console.log("Whoops!", error) //DEBUG
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={'FOS Transfers'} />
                <div>
                    <StatsView stats={pageData.stats}/>
                </div>
                {dataReady && 
                    <div>
                        <h3>Cases</h3>
                        <DataTable 
                            data={tableData} 
                            viewButtons={true} 
                            viewBaseUrl='/view-case/' 
                            viewUrlColumn='case_ref'
                            search={true}
                            searchOnChange={(value: any) => {if(value.length > 2) setSearchTerm(value)}}
                            exportButton={true}
                        />
                    </div>
                }
            </MainView>
        </div>
    )
}
