import { selectObjectProps } from "../../interfaces";

export const foaSelect: Array<selectObjectProps> = [
    {
        value: "not sent",
        label: "not sent",
    },
    {
        value: "FOA Link sent",
        label: "FOA Link sent",
    },
    {
        value: "FOA Signed",
        label: "FOA Signed",
    },
    {
        value: "FOA Signed - POA",
        label: "FOA Signed - POA",
    },
    {
        value: "FOA Complete",
        label: "FOA Complete",
    },
    {
        value: "cancelled",
        label: "cancelled",
    },
];
