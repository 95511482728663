import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'
import api from '../../../helpers/apiRequester'
import Select from 'react-select'
import { feeEarners } from '../../../data'
import { selectObjectProps } from '../../../interfaces'
import { BulkCaseSelector } from '../../reusable/bulkCaseSelector'

export const DsarLoaBulkSendModalContent = () => {
    const [bulkPayload, setBulkPayload] = useState<any>({ feeEarner: '', cases: [] })
    const [file, setFile] = useState()
    const [fileRefs, setFileRefs] = useState([])
    const fileinput = useRef<any>()
    let randomString = Math.random().toString(36)
    const [inputKey, setInputKey] = useState(randomString)
    const [sendConfirmmed, setSendConfirmmed] = useState(false)

    useEffect(() => {
        if (fileRefs.length) {
            console.log('file refs', fileRefs)
            setInputKey(randomString)
        }
    }, [fileRefs])

    useEffect(() => {
        if (file) {
            console.log(file)
            const reader = new FileReader()
            reader.onload = function (e: any) {
                const content = e.target.result
                const formattedRefs = content.replaceAll(',', '').split('\n')
                setFileRefs(formattedRefs)
            }
            reader.readAsText(file)
        }
    }, [file])

    const updateCases = (groupToSave: Array<any>) => {
        setBulkPayload({ feeEarner: bulkPayload.feeEarner, cases: groupToSave })
    }

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    }
    const confirmBulkFoaSend = async () => {
        await Confirm.show(
            'Are you sure?',
            'A DSAR LOA portal link will be sent to the clients for each of the cases selected.',
            "Send FOA's",
            'Cancel',
            () => send(),
            () => {}
        )
        const send = async () => {
            await bulkFoaSend()
        }
    }

    const bulkFoaSend = async () => {
        if (!bulkPayload.cases.length) {
            return Report.failure('Error', 'Please select cases to send an LOA portal link.', 'Okay')
        }
        Loading.circle('Attempting to send links, please wait.')
        try {
            const authUserString = window.sessionStorage.getItem('user')
            if (authUserString) {
                const authUser = JSON.parse(authUserString)
                bulkPayload.senderEmail = authUser.email
            }
            console.log('sending api request...')

            const apiResult = await api({
                url: 'actions/bulk',
                data: { action: 'send-loa-portal-links', bulkPayload },
            })
            Loading.remove()
            if (apiResult.data.success)
                return Report.success(
                    'Links Sending',
                    'Portal links have started sending, you will receive an email when the operation is complete.',
                    'Okay'
                )
            console.log('sent api request...')
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            if (error?.response.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                Report.failure(
                    'An error occurred',
                    'An error occurred whilst starting the portal link distribution process.',
                    'Okay'
                )
            }
        }
    }

    return (
        <div className={styles.FeeEarnerBulkUpdate}>
            <h2>Bulk Send LOA's</h2>
            <form>
                <input type="file" onChange={handleFileChange} key={inputKey} />
            </form>
            <div className={styles.casesToAssign}>
                <BulkCaseSelector saveGroup={updateCases} caseImport={fileRefs} />
            </div>
            <button className={styles.assignButton} onClick={(e) => confirmBulkFoaSend()}>
                Send FOA's
            </button>
        </div>
    )
}
