import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import Modal from '../../reusable/modal'
import { FeeEarnerBulkUpdateModalContent } from '../../modalContent/feeEarnerBulkUpdate'
import { DsarLoaBulkSendModalContent } from '../../modalContent/dsarLoaBulkSend'
// import { PocIssueBatchModalContent } from "../../modalContent/createPocIssueBatch";

export const BulkActionsView = () => {
    // const [showView, setShowView] = useState("details");
    const [bulkFeeEarner, setShowBulkFeeEarner] = useState(false)
    const [showPocIssueBatch, setShowPocIssueBatch] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('')

    useEffect(() => {
        setShowModal(modalType != '')
    }, [modalType])

    const toggleBulkModal = () => {
        setModalType('')
    }

    const OpenModal = (modalToOpen: string) => {
        setModalType(modalToOpen)
    }

    const modalSelector = () => {
        switch (modalType) {
            case 'fee-earner':
                return <FeeEarnerBulkUpdateModalContent />
                break

            case 'bulk-foa':
                return <DsarLoaBulkSendModalContent />
                break

            default:
                break
        }
    }
    return (
        <div className={styles.bulkActionsView}>
            <div className={styles.outerBulkActionsContainer}>
                <div className={styles.bulkActionsContainer}>
                    <h4>
                        <span>Bulk Update Fee Earners</span>
                        <span>
                            <button onClick={(e) => OpenModal('fee-earner')}>Bulk Update</button>
                        </span>
                    </h4>
                    <h4>
                        <span>Bulk Send DSAR LOA's</span>
                        <span>
                            <button onClick={(e) => OpenModal('bulk-foa')}>Multi LOA</button>
                        </span>
                    </h4>
                    {/* <h4>
                        <span>Part 36</span>
                        <span>
                            <button onClick={(e) => togglePocIssueBatchModal()}>Create Batch</button>
                        </span>
                    </h4> */}
                </div>
            </div>
            <Modal modalState={showModal} previewModalAction={toggleBulkModal}>
                {modalSelector()}
            </Modal>
            {/* <Modal modalState={showPocIssueBatch} previewModalAction={togglePocIssueBatchModal}></Modal> */}
        </div>
    )
}
