import s from "./styles.module.scss";

export interface mainProps {
    children: React.ReactNode;
}

export const MainView = (props: mainProps) => {
    const { children } = props;
    return <div className={s.main}>{children}</div>;
};
