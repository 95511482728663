import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { StatsView } from "../components/views";
import { DataView } from "../components/views/dataBreakdownView";

function Poc() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pocData, setPocData] = useState<any>();

    useEffect(() => {
        if (!dataReady && !loading) {
            Loading.circle("Loading...");
            console.log("LOADING.... GET POC DATA");
            setLoading(true);
            getData();
            // Loading.circle("Loading...");
        }
    }, [dataReady, loading]);

    const getData = async () => {
        try {
            const apiResponse = await api("/poc-data");
            const data = apiResponse.data;
            console.log("POC DATA: ", data);

            setPocData(data);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("error getting data from api....");
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="poc">
            <Sidebar />
            <MainView>
                <ViewTitle text={"POC & N1 Overview"} />
                <div>{dataReady ? <StatsView stats={pocData.stats} /> : <></>}</div>
                <div>
                    {dataReady ? <DataView dataViewTitle="POC & N1 Batches Issued" dataType="POC Issued" data={pocData.pocBatchFigures} /> : <></>}
                </div>
                <div>
                    {dataReady ? (
                        <DataView dataViewTitle="POC & N1 Chase Campaigns" dataType="POC Chase" data={pocData.chaseHistoryFigures} />
                    ) : (
                        <></>
                    )}
                </div>
            </MainView>
        </div>
    );
}

export default Poc;
