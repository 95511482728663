import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { StatTile, ViewTitle } from '../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import api from '../../../../helpers/apiRequester'
import { CaseView } from '../../caseViewContainer'
import Modal from '../../../reusable/modal'
import { RecentStatsModalContent } from '../../../modalContent/recentStatsContent'
import { CreateNewCaseModalContent } from '../../../modalContent/createNewCaseContent'
export interface statsProps {
    data: any
    client: any
    requestData: any
}
export const AllCases = (props: statsProps) => {
    const navigate = useNavigate()
    const { data, client, requestData } = props
    const [currentCase, setCurrentCase] = useState(data[0])
    const [createNewCase, setCreateNewCase] = useState(false)
    const [recentStatsData, setRecentStatsData] = useState<any>({})
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }
    useEffect(() => {
        console.log('data:', data)
    }, [data])

    const handleClick = (caseRef: string) => {
        const caseToSet = data.find((matter: any) => matter.case_ref == caseRef)
        setCurrentCase(caseToSet)
    }

    const handleAddNewClaimClick = () => {
        toggleModal()
    }

    const buttonState = (dupe: number, cancelled: number) => {
        if (dupe === 1) {
            return 'Duplicate'
        }
        if (cancelled === 1) {
            return 'Cancelled'
        }
        return 'Active'
    }

    const toggleModal = () => {
        setCreateNewCase(!createNewCase)
    }

    return (
        <div className={styles.allCasesView}>
            <h3>
                All Cases
                <button className={``} onClick={(e) => handleAddNewClaimClick()}>
                    <span>Add New claim</span>
                </button>
            </h3>
            <br />
            <div className={styles.viewContainer}>
                <div className={styles.caseSelector}>
                    {data.map((item: any, k: number) => {
                        return (
                            <button
                                className={`${currentCase.case_ref == item.case_ref ? styles.selected : ''} ${
                                    styles.caseButton
                                } ${item.cancelled ? styles.inactiveCase : styles.activeCase}`}
                                key={k}
                                onClick={(e) => {
                                    handleClick(item.case_ref)
                                }}
                            >
                                <span>{buttonState(item.marked_duplicate, item.cancelled)}</span>
                                <span>{item.case_ref}</span>
                            </button>
                        )
                    })}
                </div>
                <div className={styles.caseViewContainer}>
                    <CaseView data={currentCase} />
                </div>
            </div>

            <Modal modalState={createNewCase} previewModalAction={toggleModal}>
                <CreateNewCaseModalContent clientData={client} closeModal={toggleModal} refreshData={requestData} />
            </Modal>
        </div>
    )
}
