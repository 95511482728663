import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../../helpers/apiRequester";
import Select from "react-select";
export interface statsProps {
    data: any;
}
export interface stepInferface {
    title: string;
    progress: string;
}
export const ProgressView = (props: statsProps) => {
    const navigate = useNavigate();
    const { data } = props;
    const [steps, setSteps] = useState<Array<stepInferface>>([]);

    const progressItems: any = {
        incomplete: (
            <>
                <div className={styles.incomplete}></div>
            </>
        ),
        complete: (
            <>
                <div className={styles.complete}></div>
            </>
        ),
        cancelled: (
            <>
                <div className={styles.cancelled}></div>
            </>
        ),
    };

    useEffect(() => {
        console.log("DATAVIEW : ", data);

        const stepsArray = [
            { title: "Welcome Letter", progress: data.welcome_letter },
            { title: "FOA", progress: data.foa },
            { title: "LBA", progress: data.lba },
            { title: "FOA Response", progress: data.lba_foa_response },
            { title: "POC & N1", progress: data.poc_n1_v2_sent },
            { title: "Cancelled", progress: data.cancelled == "1" ? "YES" : "NO" },
        ];
        setSteps(stepsArray);
    }, [data]);

    const isComplete = (step: stepInferface) => {
        if (step.title == "FOA" && step.progress == "FOA Link sent") {
            return styles.orange;
        }
        if (step.title == "FOA" && (step.progress == "FOA Complete" || step.progress == "FOA Signed" || step.progress == "FOA Signed - POA")) {
            return styles.success;
        }
        if (step.title == "Welcome Letter" && step.progress == "sent") {
            return styles.success;
        }
        if (step.title == "Welcome Letter" && step.progress == "not sent") {
            return styles.fail;
        }
        if (step.title == "LBA" && step.progress == "sent") {
            return styles.success;
        }
        if (step.title == "FOA Response" && step.progress == "sent") {
            return styles.success;
        }
        if (step.title == "POC & N1" && step.progress == "sent") {
            return styles.success;
        }
        if (step.title == "Cancelled" && step.progress == "YES") {
            return styles.fail;
        }
    };

    return (
        <div className={styles.progressViewContainer}>
            <div className={styles.progressView}>
                {steps &&
                    steps?.map((step: stepInferface, k: number) => {
                        return (
                            <div key={k} className={styles.ProgressItemContainer}>
                                <div className={styles.progessCircleContainer}>
                                    <div className={`${isComplete(step)} ${styles.progessCircle}`}></div>
                                </div>
                                <p>
                                    <span>{step.title}</span>
                                    <span>{step.progress}</span>
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
