import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
export interface statsProps {
    dataType: string;
    data: any;
    dataViewTitle: string;
}

export const DataView = (props: statsProps) => {
    const { data, dataType, dataViewTitle } = props;
    const [showStats, setShowStats] = useState(false);
    const [showMessage, setShowMessage] = useState("");

    useEffect(() => {
        // console.log("DATAVIEW : ", data);

        if (!showStats && data && data.length > 0) {
            // console.log("DATA AVAILABLE....", data);
            setShowStats(true);
        }
    }, [data]);

    const getTableBody = () => {
        return (
            <tbody>
                {data.map((elem: any, i: number) => {
                    return (
                        <tr key={i}>
                            <td>{elem.title}</td>
                            {elem.values.map((value: any, i2: number) => {
                                // console.log(i2, "value: ", Object.keys(value)[0]);

                                if (Object.keys(value)[0] === "Download Link A" || Object.keys(value)[0] === "Download Link B") {
                                    return (
                                        <td key={i2}>
                                            <div dangerouslySetInnerHTML={{ __html: `${Object.values(value)[0]}` }} />
                                        </td>
                                    );
                                }
                                if (Object.keys(value)[0] === "Message") {
                                    return (
                                        <td key={i2}>
                                            <span data-msgid={`${i}`} onClick={(e: any) => setShowMessage(`${e.target.getAttribute("data-msgID")}`)}>
                                                Click here to view
                                                <div className={`${showMessage === `${i}` ? styles.display : ""} `}>
                                                    <div dangerouslySetInnerHTML={{ __html: `${Object.values(value)[0]}` }} />
                                                </div>
                                            </span>
                                        </td>
                                    );
                                }
                                return <td key={i2}>{`${Object.values(value)[0]}`}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    return (
        <div className={styles.dataView}>
            <h3>{dataViewTitle}</h3>
            <div className={styles.dataTableContainer}>
                <table>
                    {showStats ? (
                        <>
                            {getTableHeaders(dataType)}
                            {getTableBody()}
                        </>
                    ) : (
                        <></>
                    )}
                </table>
            </div>
        </div>
    );
};

const getTableHeaders = (type: string) => {
    switch (type) {
        case "FOA":
            return (
                <thead>
                    <tr>
                        <th>Lender</th>
                        <th>Active Cases</th>
                        <th>Sent</th>
                        <th>Not Signed</th>
                        <th>Partial</th>
                        <th>Complete</th>
                    </tr>
                </thead>
            );
            break;
        case "FOA-RESPONSE":
            return (
                <thead>
                    <tr>
                        <th>Lender</th>
                        <th>Active Cases</th>
                        <th>Sent</th>
                        <th>Outstanding</th>
                        <th>Ready to Send</th>
                    </tr>
                </thead>
            );
            break;

        case "FOA Chase":
            return (
                <thead>
                    <tr>
                        <th>Chase Date</th>
                        <th>Total Sent</th>
                        <th>Communication Type</th>
                        <th>Communication Group ID</th>
                        <th>Message</th>
                    </tr>
                </thead>
            );
            break;

        case "POC Chase":
            return (
                <thead>
                    <tr>
                        <th>Chase Date</th>
                        <th>Total Sent</th>
                        <th>Communication Type</th>
                        <th>Communication Group ID</th>
                        <th>Message</th>
                    </tr>
                </thead>
            );
            break;

        case "POC Issued":
            return (
                <thead>
                    <tr>
                        <th>POC Batch ID</th>
                        <th>Total Cases</th>
                        <th>Sent Date </th>
                        <th>Batch Document A</th>
                        <th>Batch Document B</th>
                    </tr>
                </thead>
            );
            break;

        default:
            break;
    }
};
