import { useEffect, useState } from "react";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { ClientView } from "../components/views/singleClientView";
import { CaseView } from "../components/views/caseViewContainer";

function ViewCase() {
    const navigate = useNavigate();
    const [caseData, setCaseData] = useState<any>();
    const [clientData, setClientData] = useState<any>();
    const [caseRef, setCaseRef] = useState<string>("");
    const { url_case_ref } = useParams();

    useEffect(() => {
        if (url_case_ref && caseRef == "") {
            setCaseRef(url_case_ref);
            Loading.circle("Loading...");
            // console.log("LOADING.... GET CASE DATA");
            getData(url_case_ref);
        }
    }, [caseRef]);

    const getData = async (url_case_ref: string) => {
        try {
            // console.log("CASEREF: ", url_case_ref);

            const apiResponse = await api({
                url: "view-case",
                data: { case_ref: url_case_ref },
            });
            const data = apiResponse.data;

            if (data.success) {
                setCaseData(data.caseInfo);
                setClientData(data.clientInfo);
            }

            // console.log("CASE DATA: ", data);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("error getting data from api....");
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="viewCase">
            <Sidebar />
            <MainView>
                <ViewTitle text={"Case Reference: " + caseRef} />
                <div>
                    {clientData ? <ClientView data={clientData} /> : <></>}
                    {caseData ? <CaseView data={caseData} /> : <></>}
                </div>
            </MainView>
        </div>
    );
}

export default ViewCase;
