import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import { Wrapper } from "../../reusable";

function Footer() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    {/* <img src={logo} /> */}
                    <p className={styles.disclaimer}>
                        Sentinel legal Ltd is Registered in England and Wales. Registered office Harley house, 29 Cambray Place, Cheltenham, England,
                        GL50 1JN. Authorised and regulated by the Solicitors Regulation Authority. SRA number 811792. Phone: 0161 528 9544
                    </p>
                </div>
            </Wrapper>
        </div>
    );
}

export default Footer;
