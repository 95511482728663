import styles from './styles.module.scss'
import pageStyles from '../../../globals/pages.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import { Wrapper } from '../../reusable'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import rightArrow from '../../../assets/right-arrow.png'
import { IsMobile } from '../isMobile/isMobile'
import axios from 'axios'
import { useParams } from 'react-router-dom'

function Sidebar() {
    return (
        <div className={`${styles.sidebar}  ${responsiveStyles.sidebar} `}>
            <div className={styles.sidebarHeader}>
                <img src={logo} alt="dashboard logo" />
            </div>

            <div className={styles.sidebarLinks}>
                <ul>
                    <li>
                        <Link to={'/home'}>Home</Link>
                    </li>
                    <li>
                        <Link to={'/dsar-stats'}>DSAR Stats</Link>
                    </li>
                    <li>
                        <Link to={'/loc-stats'}>LOC Stats</Link>
                    </li>
                    <li>
                        <Link to={'/cases'}>Cases</Link>
                    </li>
                    <li>
                        <Link to={'/clients'}>Clients</Link>
                    </li>
                    <li>
                        <Link to={'/bulk-actions'}>Bulk Actions</Link>
                    </li>
                    {/* <li>
                        <Link to={'/fos-imports'}>FOS Imports</Link>
                    </li> */}
                    {/* <li>
                        <Link to={'/introducers'}>Introducers</Link>
                    </li>
                    <li>
                        <Link to={'/FOA'}>FOAs</Link>
                    </li>
                    <li>
                        <Link to={'/FOA-responses'}>FOA Responses</Link>
                    </li>
                    <li>
                        <Link to={'/fos-introducers'}>FOS Introducers</Link>
                    </li>
                    <li>
                        <Link to={'/fos-transfers'}>FOS Transfers</Link>
                    </li>
                    <li>
                        <Link to={'/POC'}>POCs</Link>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
