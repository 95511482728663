import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'
import api from '../../../helpers/apiRequester'
import Select from 'react-select'
import { feeEarners } from '../../../data'
import { selectObjectProps } from '../../../interfaces'
import { BulkCaseSelector } from '../../reusable/bulkCaseSelector'
import { SubmitButton } from '../../reusable'

interface newCaseInterface {
    clientData: any
    refreshData: any
    closeModal: any
}
export const CreateNewCaseModalContent = (props: newCaseInterface) => {
    const [client, setClient] = useState<any>(-1)
    const { clientData, refreshData, closeModal } = props
    const [caseData, setCaseData] = useState({})

    useEffect(() => {
        console.log('from modal: ', clientData)
        if (clientData) setClient(clientData)
    }, [clientData])

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempCaseData: any = { ...caseData }
        tempCaseData[e.currentTarget.name] = e.currentTarget.value
        setCaseData(tempCaseData)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const createCase = async () => {
        try {
            Loading.circle('Creating case, please wait.')
            console.log('CREATE THE CASE....', client)
            const payload = { client_id: client.id, ...caseData }
            console.log('case data: ', payload)
            const apiResponse = await api({
                url: 'create-case',
                data: payload,
            })
            const data = apiResponse.data

            if (data.success) {
                refreshData()
                Loading.remove()
                Report.success('Success', 'The claim has been successfully added.', 'ok', () => {
                    closeModal()
                })
            }
        } catch (error: any) {
            console.log('Error creating case:~', error.message)
            Loading.remove()
            Report.failure('An error occurred', 'An error occurred when creating a case.', 'Okay')
        }
    }

    const confirmClaimCreate = () => {
        Confirm.show(
            'Confirm New Claim',
            'Clicking Confirm will action a CFA link to be sent to the client.',
            'Confirm',
            'Cancel',
            () => createCase(),
            () => {}
        )
    }

    return (
        <div className={styles.newCasesModalContainer}>
            <h2>Add A Claim</h2>
            <div className={styles.newCasesModal}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <h3>Vehicle Details</h3>
                    <div>
                        <fieldset>
                            <label>Vehicle Make</label>
                            <input className={styles.textInput} name={'vehicle_make'} type="text" onChange={onChange} />
                        </fieldset>
                        <fieldset>
                            <label>Vehicle Model</label>
                            <input
                                className={styles.textInput}
                                name={'vehicle_model'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Vehicle Registration</label>
                            <input
                                className={styles.textInput}
                                name={'vehicle_registration'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                    </div>
                    <hr />
                    <h3>Policy Details</h3>
                    <div>
                        <fieldset>
                            <label>Policy Number</label>
                            <input
                                className={styles.textInput}
                                name={'policy_number'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Lender</label>
                            <input className={styles.textInput} name={'lender'} type="text" onChange={onChange} />
                        </fieldset>

                        <fieldset>
                            <label>Interest Rate</label>
                            <input className={styles.textInput} name={'apr'} type="text" onChange={onChange} />
                        </fieldset>
                        <fieldset>
                            <label>Credit Amount</label>
                            <input
                                className={styles.textInput}
                                name={'credit_amount'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                    </div>
                    <hr />
                    <h3>Defendant Details</h3>
                    <div>
                        <fieldset>
                            <label>Defendant Address</label>
                            <input
                                className={styles.textInput}
                                name={'defendant_address'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Defendant Email</label>
                            <input
                                className={styles.textInput}
                                name={'defendant_email'}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                    </div>
                    <div>
                        <SubmitButton
                            text={'Create Claim'}
                            onClick={() => {
                                confirmClaimCreate()
                            }}
                            btnType="primary"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
