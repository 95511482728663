import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../helpers/apiRequester";
import Select from "react-select";
import { selectObjectProps } from "../../../interfaces";
import { feeEarners } from "../../../data";
export interface statsProps {
    case_ref: string;
    toggleModal: (refresh?: boolean | null) => void;
}

export const PocModalView = (props: statsProps) => {
    const { case_ref, toggleModal } = props;
    const [pocData, setPocData] = useState<any>({});

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempPocData = { ...pocData };
        tempPocData[e.currentTarget.name] = e.currentTarget.value;
        setPocData(tempPocData);
    };

    const saveData = async () => {
        Loading.circle("Saving");
        try {
            const apiResult = await api({
                url: "save-poc-info",
                data: { case_ref, pocData },
            });
            Loading.remove();
            if (!apiResult.data.success) return Report.failure("Error", "An error occurred.", "Okay");
            Report.success("Success", "You saved poc data successfully.", "Okay", () => {
                toggleModal(true);
            });
        } catch (error) {
            Report.failure("Update Failed", `${error}`, "Okay");
        }
    };

    return (
        <div className={styles.pocModal}>
            <div className={styles.pocModalContainer}>
                <div>
                    <h2>
                        Add new poc data
                        <button
                            onClick={(e) => {
                                toggleModal();
                            }}
                        >
                            Close
                        </button>
                    </h2>
                    {/* <form onSubmit={(e) => handleSubmit(e)}> */}
                    <div className={styles.pocDataDiv}>
                        <h4>Particulars of Claim</h4>
                        <fieldset>
                            <label>Finance Agreement Date</label>
                            <input
                                className={styles.textInput}
                                value={pocData.finance_agreement_date}
                                name={"finance_agreement_date"}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Broker</label>
                            <input className={styles.textInput} name={"broker"} value={pocData.broker} type="text" onChange={onChange} />
                        </fieldset>
                        <fieldset>
                            <label>Cash Price</label>
                            <input className={styles.textInput} value={pocData.cash_price} name={"cash_price"} type="text" onChange={onChange} />
                        </fieldset>
                        <fieldset>
                            <label>Deposit</label>
                            <input className={styles.textInput} value={pocData.deposit} name={"deposit"} type="text" onChange={onChange} />
                        </fieldset>
                        <fieldset>
                            <label>Total Amount Payable</label>
                            <input
                                className={styles.textInput}
                                value={pocData.total_amount_payable}
                                name={"total_amount_payable"}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Total Cost of Credit</label>
                            <input
                                className={styles.textInput}
                                name={"total_credit_cost"}
                                value={pocData.total_credit_cost}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>First Repayment</label>
                            <input
                                className={styles.textInput}
                                name={"first_repayment"}
                                value={pocData.first_repayment}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Monthly Repayment</label>
                            <input
                                className={styles.textInput}
                                name={"monthly_repayment"}
                                value={pocData.monthly_repayment}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset>
                            <label>Final Repayment</label>
                            <input
                                className={styles.textInput}
                                name={"final_repayment"}
                                value={pocData.final_repayment}
                                type="text"
                                onChange={onChange}
                            />
                        </fieldset>
                        <fieldset> </fieldset>
                    </div>
                    <div className={styles.pocDataDiv}>
                        <h4>Document Information</h4>
                        <div className={styles.pocDataDiv}>
                            <fieldset>
                                <label>Defendant Name</label>
                                <input
                                    className={styles.textInput}
                                    name={"defendant_name"}
                                    value={pocData.defendant_name}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant for Service Name</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_defendant_name"}
                                    value={pocData.service_defendant_name}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                        </div>
                        <h4>Defendant Information</h4>
                        <div className={styles.formDiv}>
                            <fieldset>
                                <label>Defendant Building Name</label>
                                <input className={styles.textInput} name={"building"} value={pocData.building} type="text" onChange={onChange} />
                            </fieldset>
                            <fieldset>
                                <label>Defendant House No. Street name</label>
                                <input
                                    className={styles.textInput}
                                    name={"house_no_street_name"}
                                    value={pocData.house_no_street_name}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant Town</label>
                                <input className={styles.textInput} name={"town"} value={pocData.town} type="text" onChange={onChange} />
                            </fieldset>
                            <fieldset>
                                <label>Defendant City</label>
                                <input className={styles.textInput} name={"city"} value={pocData.city} type="text" onChange={onChange} />
                            </fieldset>
                            <fieldset>
                                <label>Defendant Postcode</label>
                                <input className={styles.textInput} name={"postcode"} value={pocData.postcode} type="text" onChange={onChange} />
                            </fieldset>
                        </div>
                        <div className={styles.formDiv}>
                            <fieldset>
                                <label>Defendant for Service Building Name</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_building"}
                                    value={pocData.service_building}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant for Service House No. Street name</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_house_no_street_name"}
                                    value={pocData.service_house_no_street_name}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant for Service Town</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_town"}
                                    value={pocData.service_town}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant for Service City</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_city"}
                                    value={pocData.service_city}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Defendant for Service Postcode</label>
                                <input
                                    className={styles.textInput}
                                    name={"service_postcode"}
                                    value={pocData.service_postcode}
                                    type="text"
                                    onChange={onChange}
                                />
                            </fieldset>
                        </div>
                        <button className={styles.save} onClick={saveData}>
                            Save Data
                        </button>
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
};
