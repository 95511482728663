import { BrowserRouter as Router, Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom'
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop'

import Main from './containers/main'
import Auth from './containers/auth'
import Home from './containers/home'
import Login from './containers/login'
import Foa from './containers/foa'
import LenderFoaResponses from './containers/foaResponse'
import Poc from './containers/poc'
import Cases from './containers/cases'
import Clients from './containers/clients'
import ViewCase from './containers/viewCase'
import ViewClient from './containers/viewClient'
import BulkActions from './containers/bulkActions'
import IntroducedCases from './containers/introducedCases'
import FosCases from './containers/fosCases'
import FosIntroducers from './containers/fosIntroducers'
import FosTransfers from './containers/fosTransfers'
import DsarStats from './containers/dsarStats'
import LocStats from './containers/locStats'
// import { Routes, Route } from "react-router-dom";

export default function AppRouter() {
    // Router

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route index element={<Login />} />
                    <Route path="auth" element={<Auth />} />
                    <Route path="login" element={<Login />} />
                    <Route path="home" element={<Home />} />
                    <Route path="FOA" element={<Foa />} />
                    <Route path="foa-responses" element={<LenderFoaResponses />} />
                    <Route path="POC" element={<Poc />} />
                    <Route path="cases" element={<Cases />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="view-case/:url_case_ref" element={<ViewCase />} />
                    <Route path="view-client/:url_client" element={<ViewClient />} />
                    <Route path="bulk-actions" element={<BulkActions />} />
                    <Route path="introducers" element={<IntroducedCases />} />
                    <Route path="FOS-imports" element={<FosCases />} />
                    <Route path="FOS-introducers" element={<FosIntroducers />} />
                    <Route path="FOS-transfers" element={<FosTransfers />} />
                    <Route path="dsar-stats" element={<DsarStats />} />
                    <Route path="loc-stats" element={<LocStats />} />
                </Route>
            </Routes>
        </Router>
    )
}
