import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../reusable";
export interface statsProps {
    stats: any;
}

export const StatsView = (props: statsProps) => {
    const { stats } = props;
    const [showStats, setShowStats] = useState(false);

    useEffect(() => {
        // console.log("CHECK STATS", stats);

        if (!showStats && stats.length > 0) {
            // console.log("STATS AVAILABLE....", stats);
            setShowStats(true);
        }
    }, [stats]);

    const displayStats = () => {
        return stats.map((elem: any, i: number) => {
            return (
                <div className={styles.statGroup} key={i}>
                    <h3 className={styles.statGroupTitle}>{elem.title}</h3>
                    <div className={styles.statsContainer}>
                        {elem.values.map((value: any, i2: number) => {
                            return <StatTile key={i2} title={Object.keys(value)[0]} stat={`${Object.values(value)[0]}`} />;
                        })}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.statsView}>
            <div>{showStats ? <>{displayStats()}</> : <></>}</div>
        </div>
    );
};
