import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { StatsView } from "../components/views";
import { DataView } from "../components/views/dataBreakdownView";

function Foa() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [foaData, setFoaData] = useState<any>();

    useEffect(() => {
        if (!dataReady && !loading) {
            // console.log("LOADING.... GET DATA");
            Loading.circle("Loading...");
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    const getData = async () => {
        try {
            const apiResponse = await api("/foa-data");
            const data = apiResponse.data;
            // console.log("FOA DATA: ", data);

            setFoaData(data);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("");
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="foa">
            <Sidebar />
            <MainView>
                <ViewTitle text={"Form of Authority Overview"} />
                <div>{dataReady ? <StatsView stats={foaData.stats} /> : <></>}</div>
                {dataReady ? <DataView dataViewTitle="FOA to Client Breakdown" dataType="FOA" data={foaData.lenderFoaFigures} /> : <></>}
                <div>
                    {dataReady ? <DataView dataViewTitle="FOA Chase Campaigns" dataType="FOA Chase" data={foaData.chaseHistoryFigures} /> : <></>}
                </div>
            </MainView>
        </div>
    );
}

export default Foa;
