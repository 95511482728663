import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import s from "./styles.module.scss";
import 'react-tabs/style/react-tabs.css';

export interface tabsProps {
    tabs:         
        {
            title: String,
            panel: any,
        }[]
}

export const TabsBlock = (props: tabsProps) => {
    const { tabs } = props;

    const tabList: any = []
    const tabPanels: any = []

    for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];

        tabList.push(<Tab>{tab.title}</Tab>)
        tabPanels.push(<TabPanel>{tab.panel}</TabPanel>)
    }

    return (
        <div className={s.tabsContainer}>
            <Tabs>
                <TabList>
                    {tabList}
                </TabList>
                {tabPanels}
            </Tabs>
        </div>
    );
};

