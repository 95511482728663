import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { StatsView } from "../components/views";
import Select from "react-select";

import globalStyles from "../globals/pages.module.scss";

interface selectObjectProps {
    value: string;
    label: string;
}
const dataCaseTypeSetOptions: Array<selectObjectProps> = [
    {
        value: "PCP",
        label: "PCP Cases",
    },
    {
        value: "BEC",
        label: "Business Eneregy Cases",
    },
];
// const dataIntroducerSetOptions: Array<selectObjectProps> = [
//     {
//         value: "mer",
//         label: "My Energy Refunds",
//     },
//     {
//         value: "CCD",
//         label: "Car Claims Direct",
//     },
// ];

let bec = [
    {
        title: "Leads",
        values: [{ "Total Leads": 0 }],
    },
    {
        title: "Letter of Authority",
        values: [{ "LOA sent": 0 }, { "LOA Received": 0 }],
    },
    {
        title: "Credit Report Status",
        values: [{ "Awaiting credit report": 0 }, { "Credit report complete": 0 }],
    },
    {
        title: "Information Requests",
        values: [{ "Information Request sent": 0 }, { "Information Request back": 0 }, { "Information Request below quantum": 0 }],
    },
    {
        title: "CFA's",
        values: [{ "CFA sent": 0 }, { "CFA signed": 0 }, { "CFA not signed": 0 }],
    },
    {
        title: "Know Your Customer",
        values: [{ "KYC started": 0 }, { "KYC complete": 0 }],
    },
    {
        title: "Case Extras",
        values: [{ "EW report request": 0 }, { "EW report received": 0 }],
    },
];
let pcp = [
    {
        title: "Leads",
        values: [{ "Total Leads": 0 }, { "Packaged cases ready for transfer": 0 }],
    },
    {
        title: "Data Subject Access Requests",
        values: [{ "DSAR sent": 0 }, { "DSAR back": 0 }, { "DSAR below quantum": 0 }],
    },
    {
        title: "Credit Report Status",
        values: [{ "Awaiting credit report": 0 }, { "Credit report complete": 0 }],
    },
    {
        title: "CFA's",
        values: [{ "CFA sent": 0 }, { "CFA signed": 0 }, { "CFA not signed": 0 }],
    },
    {
        title: "Know Your Customer",
        values: [{ "KYC started": 0 }, { "KYC complete": 0 }],
    },
    {
        title: "Case Extras",
        values: [{ "EW report request": 0 }, { "EW report received": 0 }],
    },
];
function IntroducedCases() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [overviewData, setOverviewData] = useState<any>({
        stats: pcp,
    });
    const [activeData, setActiveData] = useState<selectObjectProps>({
        value: "PCP",
        label: "PCP Cases",
    });

    useEffect(() => {
        if (!dataReady && !loading) {
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    useEffect(() => {
        getData(activeData);
    }, [activeData, loading]);

    const getData = async (type: any = null) => {
        Loading.circle("Loading...");
        try {
            // const apiResponse = await api({
            //     url: "/system-overview",
            //     data: { dataGroup: activeData.value },
            // });
            // const data = apiResponse.data;
            // console.log("SYSTEM OVERVIEW DATA: ", data);

            if (type.value == "BEC") {
                setOverviewData({
                    stats: bec,
                });
            } else {
                setOverviewData({
                    stats: pcp,
                });
            }

            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            // if (error?.response.status == 401) {
            //     Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
            //         window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            //     });
            // } else {
            //     window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            // }
        }
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log("input label: ", label);
        if (option != null) setActiveData(option);
    };

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={"Introducer Cases Overview"} />
                <div className={globalStyles.funderSelectContainer}>
                    <label>Select Claim Type</label>
                    <Select
                        className={globalStyles.select}
                        options={dataCaseTypeSetOptions}
                        onChange={(value) => onSelectChange(value, "changeDataSet")}
                        name={"changeDataSet"}
                        value={activeData}
                    />
                </div>{" "}
                {/* <div className={globalStyles.funderSelectContainer}>
                    <label>Select Introducer</label>
                    <Select
                        className={globalStyles.select}
                        options={dataIntroducerSetOptions}
                        onChange={(value) => onSelectChange(value, "changeDataSet")}
                        name={"changeDataSet"}
                        value={activeData}
                    />
                </div> */}
                <div>{dataReady ? <StatsView stats={overviewData.stats} /> : <></>}</div>
            </MainView>
        </div>
    );
}

export default IntroducedCases;
