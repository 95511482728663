import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import Select from 'react-select'

import globalStyles from '../globals/pages.module.scss'
import { TabsBlock } from '../components/reusable/tabBlock'
import { DataTable } from '../components/reusable/dataTable'

interface selectObjectProps {
    value: string
    label: string
}

let defaultStats: any = [
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' },],
    },
    {
        title: 'Loading...',
        values: [{ Loading: 'Loading...' },],
    },
]

export default function FosIntroducers() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dropdownOptions, setDropdownOptions] = useState<any>([
        {label: 'Loading...', value: null}
    ])
    const [activeOption, setActiveOption] = useState<selectObjectProps>(dropdownOptions[0])
    const [pageData, setPageData] = useState<any>({
        stats: defaultStats,
    })
    const [tablesData, setTablesData] = useState<any>([
        {title: 'Loading...', data: [
            {loading: 'loading...'}
        ]}
    ])

    // useEffect(() => {
    //     if (!dataReady && !loading) {
    //         setLoading(true)
    //         getData()
    //     }
    // }, [dataReady, loading])

    useEffect(() => {
        console.log('Active Option', activeOption) //DEBUG
        getData()
    }, [activeOption])

    const getData = async () => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({
                url: '/fos-introducers-data',
                data: { introducer: activeOption.value },
            })
            console.log("here!") //DEBUG
            const data = apiResponse.data
            console.log('data', data) //DEBUG

            const tablesArray = []
            for (let i = 0; i < data.tablesData.length; i++) {
                const table = data.tablesData[i];

                tablesArray.push({
                    title: table.title,
                    panel: <DataTable data={table.data} />
                })
            }

            setDropdownOptions(data.introducers)
            if (!activeOption.value) {
                setActiveOption(data.introducers[0])
            }
            setTablesData(tablesArray)
            setPageData(data)
            setDataReady(true)
            Loading.remove()
        } catch (error: any) {
            Loading.remove()
            if (error?.response?.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                console.log("Whoops!", error) //DEBUG
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log('option: ', option) //DEBUG
        if (option != null) setActiveOption(option)
    }

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={'FOS Cases'} />
                <div className={globalStyles.funderSelectContainer}>
                    <label>Introducer</label>
                    {dataReady ?
                        <Select
                            className={globalStyles.select}
                            options={dropdownOptions}
                            onChange={(value) => onSelectChange(value, 'changeDataSet')}
                            name={'changeDataSet'}
                            value={activeOption}
                        />
                    : 
                        <Select
                            className={globalStyles.select}
                            options={dropdownOptions}
                            onChange={(value) => {}}
                            name={'changeDataSet'}
                            value={activeOption}
                        />
                    }
                </div>
                <div><StatsView stats={pageData.stats}/></div>
                {dataReady ? <TabsBlock tabs={tablesData} /> : <TabsBlock tabs={[{title: 'Loading...', panel: <p>Loading...</p>}]}/>}
            </MainView>
        </div>
    )
}
