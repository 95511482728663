import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { StatsView } from "../components/views";
import { BreakdownView } from "../components/views/caseListView";

function Cases() {
    const navigate = useNavigate();
    const [dataReady, setDataReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [caseData, setCaseData] = useState<any>();
    const [caseBreakdownData, setCaseBreakdownData] = useState<any>();

    useEffect(() => {
        if (!dataReady && !loading) {
            // console.log("LOADING.... GET DATA");
            Loading.circle("Loading...");
            setLoading(true);
            getData();
        }
    }, [dataReady, loading]);

    const getData = async () => {
        try {
            const apiResponse = await api("/all-cases");
            const data = apiResponse.data;
            // console.log("FOA DATA: ", data);
            const collectedCaseData = { stats: data.stats };
            setCaseData(collectedCaseData);
            const collectedBreakdownCaseData = data.paginatedCaseData;
            setCaseBreakdownData(collectedBreakdownCaseData);
            setDataReady(true);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("");
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="cases">
            <Sidebar />
            <MainView>
                <ViewTitle text={"View Cases"} />
                <div>{dataReady ? <StatsView stats={caseData.stats} /> : <></>}</div>
                <div>
                    {dataReady ? (
                        <BreakdownView dataViewTitle="Search All Cases" dataType="ALL" data={caseBreakdownData} search={true} pagination={true} />
                    ) : (
                        <></>
                    )}
                </div>
            </MainView>
        </div>
    );
}

export default Cases;
