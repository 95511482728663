import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import api from "../../../../../../helpers/apiRequester";

export const PocV3Action = (props: { caseRef: string; pocLink: string }) => {
    const { caseRef, pocLink } = props;

    const pocRequest = async (action: string) => {
        Loading.circle("Loading...");
        try {
            const apiResponse = await api({ url: "/actions/poc", data: { case_ref: caseRef, action } });
            const data = apiResponse.data;
            Loading.remove();
            if (action === "preview-n1-v3" || action === "preview-poc-v3") {
                if (data.success && data.link) {
                    window.open(data.link, "_newtab");
                } else {
                    Report.failure("Error", "Failed to download POC", "OKAY");
                }
            } else if (action == "resend") {
                if (data.success && data.pocSent) {
                    Report.success("Success", "POC link resent to client.", "OKAY");
                } else {
                    Report.failure("Error", "Failed to send POC link to client", "OKAY");
                }
            } else if (action == "send-new") {
                if (data.success && data.pocSent) {
                    Report.success("Success", "POC link sent to client.", "OKAY", () => window.location.reload());
                } else {
                    Report.failure("Error", "Failed to send POC link to client", "OKAY");
                }
            }
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            Report.failure("Error", "Action failure", "OKAY");
        }
    };
    const attemptSendNewPoc = async () => {
        Confirm.show(
            "Are you sure?",
            "PE and Sentinel Database will be updated and a new POC portal link will be sent to the client using current data.",
            "SEND POC",
            "GO BACK",
            () => pocRequest("send-new-v3"),
            () => {}
        );
    };
    const attemptResendPoc = async () => {
        Confirm.show(
            "Are you sure?",
            "PE and Sentinel Database will be updated and the existing POC portal link will be sent to the client using current data.",
            "SEND POC",
            "GO BACK",
            () => pocRequest("resend-v3"),
            () => {}
        );
    };

    return (
        <div className={styles.singleAction}>
            <h4>POC & N1 V3</h4>
            <div>
                <p>
                    <span>Preview new POC V3 document:</span> <button onClick={(e: any) => pocRequest("preview-poc-v3")}>Generate Temporary POC V3</button>
                </p>
                <p>
                    <span>Preview new N1 V3 document:</span> <button onClick={(e: any) => pocRequest("preview-n1-v3")}>Generate Temporary N1 V3</button>
                </p>
                {pocLink.length > 0 ? (
                    <>
                        <p>
                            <span>Test POC V3 Link: </span>
                            <a href={pocLink} target="__blank">
                                <button>Open POC Portal</button>
                            </a>
                        </p>
                        <p>
                            <span>Resend POC V3 Link:</span> <button onClick={(e: any) => attemptResendPoc()}>Send Link</button>
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            <span>Send New POC V3 Link:</span> <button onClick={(e: any) => attemptSendNewPoc()}>Send Link</button>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};
