import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import '../src/globals/root.scss' // Import regular stylesheet
import AppRouter from './router'
import { AuthUserProvider } from './authUserProvider'
function App() {
    return (
        <AuthUserProvider>
            <div className="App">
                <AppRouter />
            </div>
        </AuthUserProvider>
    )
}

export default App
