import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../../helpers/apiRequester";
import Select from "react-select";
import { ProgressView } from "../progressView";
import { foaSelect, yesNo } from "../../../../data";
import { selectObjectProps } from "../../../../interfaces";

export interface statsProps {
    data: any;
}

export const MilestonesView = (props: statsProps) => {
    const navigate = useNavigate();
    const { data } = props;
    const [showStats, setShowStats] = useState(false);
    const [editing, setEditing] = useState(false);
    const [caseData, setCaseData] = useState(data);
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        // console.log("DATAVIEW : ", data);
        setCaseData(data);

        if (!showStats && data && data.length > 0) {
            // console.log("DATA AVAILABLE....", data);
            setShowStats(true);
        }
    }, [data]);

    useEffect(() => {
        // console.log("DATAVIEW : ", data);
        setCaseData(data);

        if (!showStats && data && data.length > 0) {
            // console.log("DATA AVAILABLE....", data);
            setShowStats(true);
        }
    }, [data]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempCaseData = { ...caseData };
        tempCaseData[e.currentTarget.name] = e.currentTarget.value;
        setCaseData(tempCaseData);
        setDataChanged(true);
    };

    const saveData = async () => {
        setEditing(false);
        console.log("SAVE DATA......");
        Loading.circle("Saving....");
        try {
            const apiResult = await api({
                url: "save-case-info",
                data: { caseDataToSave: caseData },
            });
            Loading.remove();
            if (!apiResult.data.success) {
                return Report.failure("Error", "An error occurred.", "Okay");
            }
            return Report.success("Success", "Your update was saved successfully.", "Okay");
        } catch (error: any) {
            Loading.remove();
            Report.failure("Error", `${error.message}. Please refresh the page and try again.`, "Okay");
        }
    };

    const handleEditToggle = () => {
        if (editing) {
            setCaseData(data);
        }
        setEditing(!editing);
    };

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log("input label: ", label);
        if (option != null) {
            console.log("INPUT SELECT FIELD CHANGED...", option.value);

            const field = label;
            const fieldValue = option.value;
            let valueToSave;

            if (field == "cancelled" || field == "poc_n1_v2_signed" || field == "poc_issued") {
                valueToSave = fieldValue == "Yes" ? 1 : 0;
            }
            if (
                field == "welcome_letter" ||
                field == "lba" ||
                field == "post_lba_update" ||
                field == "lba_foa_response" ||
                field == "poc_n1_v2_sent" ||
                field == "EX160_sent"
            ) {
                valueToSave = fieldValue == "Yes" ? "sent" : "not sent";
            }

            if (field == "poc_n1_v2_signed" || field == "EX160_signed") {
                valueToSave = fieldValue == "Yes" ? "YES" : "NO";
            }

            if (field == "foa") {
                valueToSave = fieldValue;
            }

            if (field == "" || valueToSave == null) {
                console.log("value or field missing...", field);
                console.log("value or field missing...", valueToSave);
                return console.log("value or field missing...");
            }

            let tempCaseData = { ...caseData };
            tempCaseData[field] = valueToSave;
            console.log("data to save : ", tempCaseData);

            setCaseData(tempCaseData);
            setDataChanged(true);
        }
    };

    const checkValue = (field: string) => {
        const fieldValue = caseData[field];

        if (field == "cancelled" || field == "poc_issued") {
            const filterValue = fieldValue == 1 ? "Yes" : "No";
            return yesNo.filter((option) => option.label === filterValue);
        }
        if (
            field == "welcome_letter" ||
            field == "lba" ||
            field == "post_lba_update" ||
            field == "lba_foa_response" ||
            field == "poc_n1_v2_sent" ||
            field == "EX160_sent"
        ) {
            const filterValue = fieldValue == "sent" ? "Yes" : "No";
            return yesNo.filter((option) => option.label === filterValue);
        }

        if (field == "poc_n1_v2_signed" || field == "EX160_signed") {
            const filterValue = fieldValue == "YES" ? "Yes" : "No";
            return yesNo.filter((option) => option.label === filterValue);
        }

        if (field == "foa") {
            return foaSelect.filter((option) => option.label === caseData["foa"]);
        }
    };

    return (
        <div className={styles.detailsView}>
            <h4>
                <span>Progress</span>
                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? "Cancel" : "Edit"}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h4>
            <div>
                <ProgressView data={data} />
            </div>
            <div className={styles.viewContainer}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>
                        <label>Cancelled</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "cancelled")}
                            name={"cancelled"}
                            isDisabled={!editing}
                            value={checkValue("cancelled")}
                        />

                        {/* <Select className={styles.textInput} name={"firstname"} type="text" onChange={onChange} disabled={!editing} /> */}
                    </fieldset>
                    <fieldset>
                        <label>Cancelled Date</label>
                        <input
                            className={styles.textInput}
                            type="text"
                            name={"cancelled_date"}
                            value={caseData.cancelled_date}
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Welcome Letter Sent</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            name={"welcome_letter"}
                            onChange={(value) => onSelectChange(value, "welcome_letter")}
                            isDisabled={!editing}
                            value={checkValue("welcome_letter")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Welcome Letter Sent Date</label>
                        <input
                            className={styles.textInput}
                            value={caseData.welcome_letter_date}
                            type="text"
                            name={"welcome_letter_date"}
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>FOA Status</label>
                        <Select
                            className={styles.select}
                            options={foaSelect}
                            onChange={(value) => onSelectChange(value, "foa")}
                            name={"foa"}
                            isDisabled={!editing}
                            value={checkValue("foa")}
                        />
                    </fieldset>
                    <fieldset></fieldset>
                    <fieldset>
                        <label>FOA Sent Date</label>
                        <input
                            className={styles.textInput}
                            name={"foa_sent_date"}
                            value={caseData.foa_sent_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>FOA Signed Date</label>
                        <input
                            className={styles.textInput}
                            name={"foa_signed_date"}
                            value={caseData.foa_signed_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Letter Before Action</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "lba")}
                            name={"lba"}
                            isDisabled={!editing}
                            value={checkValue("lba")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Letter Before Action Date</label>
                        <input
                            className={styles.textInput}
                            name={"lba_date"}
                            value={caseData.lba_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Client LBA Update Letter Sent</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "post_lba_update")}
                            name={"post_lba_update"}
                            isDisabled={!editing}
                            value={checkValue("post_lba_update")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Client LBA Update Letter Date</label>
                        <input
                            className={styles.textInput}
                            name={"post_lba_date"}
                            value={caseData.post_lba_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>FOA Sent to Lender</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "lba_foa_response")}
                            name={"lba_foa_response"}
                            isDisabled={!editing}
                            value={checkValue("lba_foa_response")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>FOA Sent to Lender Date</label>
                        <input
                            className={styles.textInput}
                            value={caseData.lba_foa_response_date}
                            name={"lba_foa_response_date"}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Sent to Client</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "poc_n1_v2_sent")}
                            name={"poc_n1_v2_sent"}
                            isDisabled={!editing}
                            value={checkValue("poc_n1_v2_sent")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Sent to Client Date</label>
                        <input
                            className={styles.textInput}
                            value={caseData.poc_n1_v2_sent_date}
                            name={"poc_n1_v2_sent_date"}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Signed</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "poc_n1_v2_signed")}
                            name={"poc_n1_v2_signed"}
                            isDisabled={!editing}
                            value={checkValue("poc_n1_v2_signed")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Signed Date</label>
                        <input
                            className={styles.textInput}
                            value={caseData.poc_n1_v2_signed_date}
                            name={"poc_n1_v2_signed_date"}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Sent to Court</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "poc_issued")}
                            name={"poc_issued"}
                            isDisabled={!editing}
                            value={checkValue("poc_issued")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Sent to Court Date</label>
                        <input
                            className={styles.textInput}
                            name={"poc_issued_date"}
                            value={caseData.poc_issued_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>POC & N1 Sent to Court Batch </label>
                        <input
                            className={styles.textInput}
                            name={"poc_issued_batch"}
                            value={caseData.poc_issued_batch}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset></fieldset>
                    <fieldset>
                        <label>EX160 Sent to Client</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "EX160_sent")}
                            name={"EX160_sent"}
                            isDisabled={!editing}
                            value={checkValue("EX160_sent")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>EX160 Sent to Client Date</label>
                        <input
                            className={styles.textInput}
                            name={"EX160_sent_date"}
                            value={caseData.EX160_sent_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>EX160 Signed</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, "EX160_signed")}
                            name={"EX160_signed"}
                            isDisabled={!editing}
                            value={checkValue("EX160_signed")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>EX160 Signed Date</label>
                        <input
                            className={styles.textInput}
                            name={"EX160_signed_date"}
                            value={caseData.EX160_signed_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
