import { selectObjectProps } from "../../interfaces";

export const yesNo: Array<selectObjectProps> = [
    {
        value: "Yes",
        label: "Yes",
    },
    {
        value: "No",
        label: "No",
    },
];
