import { useEffect, useState } from 'react'
import api from '../../../helpers/apiRequester'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import Select from 'react-select'

import globalStyles from '../../../globals/pages.module.scss'
import styles from './styles.module.scss'
import Modal from '../../reusable/modal'

interface selectObjectProps {
    value: string
    label: string
}

const sequence = [
    { status: 'Client Pack Received', position: '1' },
    { status: 'Welcome email', position: '2' },
    { status: 'Welcome Pack', position: '3' },
    { status: 'Signature Required', position: '4' },
    { status: 'Documents Required', position: '5' },
    { status: 'On Hold - Duplicate', position: '6' },
    { status: 'Credit check req', position: '7' },
    { status: 'On Hold - Not Verified', position: '8' },
    { status: 'Credit check pass', position: '9' },
    { status: 'Deceased client on hold.', position: '10' },
    { status: 'Deceased client closure', position: '11' },
    { status: 'Finance Provider Required', position: '12' },
    { status: 'ID Vetting for DSAR', position: '13' },
    { status: 'ID Verification Failed', position: '14' },
    { status: 'PCP - ID Requested', position: '15' },
    { status: 'PCP ID Chase 1', position: '16' },
    { status: 'PCP - ID Received', position: '17' },
    { status: 'DSAR to send', position: '18' },
    { status: 'DSAR sent', position: '19' },
    { status: 'DSAR Overdue', position: '20' },
    { status: 'Missing information', position: '21' },
    { status: 'Further info resubmitted', position: '22' },
    { status: 'DSAR UTL', position: '23' },
    { status: 'ID Required', position: '24' },
    { status: 'DSAR Review Required', position: '25' },
    { status: 'Quantum Required', position: '26' },
    { status: 'Quantum report required', position: '27' },
    { status: 'Quantum report vetting', position: '28' },
    { status: 'Arrears', position: '29' },
    { status: 'Criteria Fail', position: '30' },
    { status: 'Outside provisions', position: '31' },
    { status: 'AML required', position: '32' },
    { status: 'AML Failed/Referred', position: '33' },
    { status: 'Upload to repack', position: '34' },
    { status: 'Failed to upload to repack', position: '35' },
    { status: 'Third party signup required', position: '36' },
    { status: 'Case to send to third party', position: '37' },
    { status: 'Case sent to third party', position: '38' },
    { status: 'DSAR not car finance', position: '39' },
    { status: 'Low premiums paid', position: '40' },
    { status: 'Test status', position: '41' },
    { status: 'On Hold', position: '42' },
    { status: 'Erasure Refusal', position: '43' },
    { status: 'On Hold - Pre Cancellation', position: '44' },
    { status: 'Case Closed - Signature Required', position: '45' },
    { status: 'On Hold - Invalid ID', position: '46' },
    { status: 'Client cancelled', position: '47' },
]

const totalIdRequired = [
    'On Hold - Not Verified',
    'ID Verification Failed',
    'PCP - ID Requested',
    'PCP ID Chase 1',
    'ID Required',
]

export const ComparisonView = (props: any) => {
    const [reports, setReports] = useState<any>()
    const [dataSetOptions, setDataSetOptions] = useState<Array<selectObjectProps>>([])
    const [activeDataLeft, setActiveDataLeft] = useState<selectObjectProps>()
    const [activeDataRight, setActiveDataRight] = useState<selectObjectProps>()
    const [leftData, setLeftData] = useState<any>()
    const [rightData, setRightData] = useState<any>()
    const [leftFilename, setLeftFilename] = useState<any>()
    const [rightFilename, setRightFilename] = useState<any>()

    useEffect(() => {
        if (props) {
            let reportList = props.reportList
            reportList.sort((a: any, b: any) => b.unixStamp - a.unixStamp)
            setReports(reportList)

            let optsArr: Array<selectObjectProps> = []
            reportList.forEach((report: any) => {
                optsArr.push({ value: report.filename, label: `Report: ${report.date}` })
            })
            setDataSetOptions(optsArr)
        }
    }, [props])

    const getReportData = async (filename: string, side: string) => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({
                url: '/dsar-report-stats',
                data: { filename },
            })
            let data = apiResponse.data
            console.log('data', data)

            data.reportSubstats = sortSubset(data.reportSubstats)

            switch (side) {
                case 'left':
                    setLeftData(apiResponse.data)

                    break
                case 'right':
                    setRightData(apiResponse.data)
                    break
            }

            Loading.remove()
        } catch (error: any) {
            Loading.remove()
            if (error?.response?.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                console.log('Whoops!', error) //DEBUG
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    const sortSubset = (subset: any) => {
        let sortedSubset: any = {}
        for (let index = 0; index < sequence.length; index++) {
            const key = sequence[index]
            if (subset[key.status]) sortedSubset[key.status] = subset[key.status]
        }
        return sortedSubset
    }
    const onSelectChangLeft = async (option: selectObjectProps | null, label: string) => {
        console.log('input label: ', label)
        console.log('input option: ', option?.value)
        if (option != null) {
            setActiveDataLeft(option)
            getReportData(option.value, 'left')
            setLeftFilename(option.value)
        }
    }
    const onSelectChangRight = (option: selectObjectProps | null, label: string) => {
        console.log('input label: ', label)
        if (option != null) {
            setActiveDataRight(option)
            getReportData(option.value, 'right')
            setRightFilename(option.value)
        }
    }

    const getTotalIdRequired = (dataArr: any) => {
        let total = 0
        totalIdRequired.forEach((item) => {
            total = total + dataArr[item]
        })
        return (
            <li>
                Total ID required <span>{total}</span>
            </li>
        )
    }

    return (
        <div className={styles.comparisonContainer}>
            <h4>Compare Reports</h4>
            <div className={styles.comparisonInnerContainer}>
                <div className={styles.leftComparison}>
                    <Select
                        className={globalStyles.select}
                        options={dataSetOptions}
                        onChange={(value) => onSelectChangLeft(value, 'changeDataSet')}
                        name={'changeDataSetLeft'}
                        value={activeDataLeft}
                    />
                    {leftData?.reportOverviewStats && (
                        <>
                            <br />
                            <a target="__blank" href={leftData.reportUrl}>
                                <button>Download report</button>
                            </a>
                            <br />
                            <div className={styles.leftData}>
                                <h4>Overview Status:</h4>
                                <ul>
                                    {Object.keys(leftData.reportOverviewStats)?.map((stat: any) => {
                                        return (
                                            <li>
                                                {stat} <span>{leftData.reportOverviewStats[stat]}</span>
                                            </li>
                                        )
                                    })}
                                    {getTotalIdRequired(leftData.reportSubstats)}
                                </ul>
                                <h4>Sub Statuses:</h4>
                                <ul>
                                    {Object.keys(leftData.reportSubstats)?.map((stat: any) => {
                                        return (
                                            <li>
                                                {stat} <span>{leftData.reportSubstats[stat]}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.rightComparison}>
                    <Select
                        className={globalStyles.select}
                        options={dataSetOptions}
                        onChange={(value) => onSelectChangRight(value, 'changeDataSet')}
                        name={'changeDataSetRight'}
                        value={activeDataRight}
                    />
                    {rightData?.reportOverviewStats && (
                        <>
                            <br />
                            <a target="__blank" href={rightData.reportUrl}>
                                <button>Download report</button>
                            </a>
                            <br />
                            <div className={styles.rightData}>
                                <h4>Overview Status:</h4>
                                <ul>
                                    {Object.keys(rightData.reportOverviewStats)?.map((stat: any) => {
                                        return (
                                            <li>
                                                {stat} <span>{rightData.reportOverviewStats[stat]}</span>
                                            </li>
                                        )
                                    })}
                                    {getTotalIdRequired(rightData.reportSubstats)}
                                </ul>
                                <h4>Sub Statuses:</h4>
                                <ul>
                                    {Object.keys(rightData.reportSubstats)?.map((stat: any) => {
                                        return (
                                            <li>
                                                {stat} <span>{rightData.reportSubstats[stat]}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
