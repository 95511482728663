import { selectObjectProps } from "../../interfaces";

/**
    'AfnanZaidi'
    'EugeniePerumal'
    'HumeiraRafiq'
    'IqraLatif'
    'LornaKaton'
    'MadeehaAhmed'
    'NadiaNisar'
    'NancyNair'
    'NolwaziNgcongo'
    'NombusoNgobese'
    'SerishaChetty' 
    'SerishaChetty' 
    'LukaszMinuczyc'
 */

export const feeEarners: Array<selectObjectProps> = [
  {
    value: "AfnanZaidi",
    label: "Afnan Zaidi",
  },
  {
    value: "EugeniePerumal",
    label: "Eugenie Perumal",
  },
  {
    value: "HumeiraRafiq",
    label: "Humeira Rafiq",
  },
  {
    value: "HearaMahmood",
    label: "Heara Mahmood",
  },
  {
    value: "IqraLatif",
    label: "Iqra Latif",
  },
  {
    value: "LornaKaton",
    label: "Lorna Katon",
  },
  {
    value: "MadeehaAhmed",
    label: "Madeeha Ahmed",
  },
  {
    value: "NadiaNisar",
    label: "Nadia Nisar",
  },
  {
    value: "NancyNair",
    label: "Nancy Nair",
  },
  {
    value: "NolwaziNgcongo",
    label: "Nolwazi Ngcongo",
  },
  {
    value: "NombusoNgobese",
    label: "Nombuso Ngobese",
  },
  {
    value: "SerishaChetty",
    label: "Serisha Chetty",
  },
  {
    value: "MabelHastings",
    label: "Mabel Hastings",
  },
  {
    value: "LukaszMinuczyc",
    label: "Lukasz Minuczyc",
  },
];
