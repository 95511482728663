import s from "./styles.module.scss";

export interface tileProps {
    title: String;
    stat: String;
}

export const StatTile = (props: tileProps) => {
    const { title, stat } = props;
    return (
        <div className={s.tileContainer}>
            <div className={s.tile}>
                <p>
                    <span className={s.small}>{title}:</span>
                    {stat}
                </p>
            </div>
        </div>
    );
};
